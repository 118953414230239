/**
 * Renders an assessment card
 */

import React from 'react';
import {Link} from 'react-router-dom';

// Helpers
import { readableMinutes } from '../../helpers';

// Translation
import translate from '../translate/Translate';

// Components
import EditAssessment from '../forms/EditAssessment';

// Icons
import * as icons from '../ui/Icons';

const AssessmentCard = class AssessmentCard extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      status: 'new'
    };
  }

  componentDidMount() {
    this.handleStatus();
  }

  /**
   * handleStatus - Determines the status of an assessment
   */
  handleStatus() {
    const { assessment } = this.props;
    let status = 'new';

    if(assessment.completedAttemptCount) {
      status = 'completed';
    } else if(assessment.activeAttempt && assessment.activeAttempt.sid) {
      status = 'in progress'
    }

    if(status !== this.state.status) {
      this.setState({
        status
      });
    }
  }

  handleEditAssessment(e, assessment) {
    e.preventDefault();

    // Update the URL without causing a page load
    // const pathname = getPathname(e.target.href);

    // window.history.pushState(
    //   {},
    //   'edit-assessment',
    //   pathname
    // );

    // Open the assessment edit drawer
    this.handleDrawer(assessment.assessmentSid);
  }

  handleDrawer(assessmentSid = null) {
    const { AppStore } = this.props.store;

    AppStore.drawerContent =
      <EditAssessment
        {...this.props}
        assessmentSid={assessmentSid}
        component="EditAssessment"
      />;

    AppStore.drawerMod = 'full';
  }

  render() {
    const {
      action,
      assessment,
      hideStatus,
      store,
      translation
    } = this.props;
    const { AssessmentStore, UserStore } = store;
    const { installSid, userData } = UserStore;

    return (
      <article
        className={`panel panel--card assessment-card${
          hideStatus ? ' assessment-card--no-status' : ''
        }`}
        data-status={hideStatus ? '' : this.state.status.replace(' ', '-')}
      >
        <div className="panel__content">
          <h2 className="panel__title panel__title--alt">
            {assessment.name}
          </h2>

          {(!hideStatus || assessment.type === 'draft') &&
            <span className={`tag tag--label tag--${assessment.type === 'draft' ? 'draft' : this.state.status.replace(' ', '-')}`}>
              {assessment.type === 'draft' ? translation.draft : this.state.status}
            </span>
          }

          {(assessment.estimatedDuration || assessment.key) &&
            <ul className="list-reset">
              {assessment.key &&
                <li>
                  <span className="meta">
                    {translation.key}
                  </span>

                  <icons.key />
                  {assessment.key}
                </li>
              }

              {assessment.estimatedDuration &&
                <li>
                  <icons.clock />
                  {translation.time.label}: {readableMinutes(assessment.estimatedDuration, translation.time)}
                </li>
              }
            </ul>
          }

          {assessment.details &&
            <p>{assessment.details}</p>
          }
        </div>

        {/* Edit assessment button for instructors and admins */}
        {action && action === 'edit' &&
          <a
            className="btn btn--outline btn--full"
            href={`/edit-assessment/${assessment.assessmentSid}`}
            onClick={(e) => this.handleEditAssessment(e, assessment)}
          >
            {translation.edit_assessment}
          </a>
        }

        {/* Start or view results button for students */}
        {action && action !== 'edit' &&
          <div className="btn-group">

            {this.state.status.toLowerCase() === 'completed' &&
              <Link
                to="/results"
              >
                <a className="btn btn--outline"
                   href="/#"
                >
                  {translation.view_results}
                </a>
              </Link>
            }
            {this.state.status.toLowerCase() !== 'completed' && this.state.status === 'in progress' &&
            <Link
              to={`/assessment/${assessment.assessmentSid}?install=${installSid}&attempt=${assessment.activeAttempt.sid}&token=${UserStore.token}`}
            >
              <a
                className="btn btn--outline"
                href="/#"
              >
                {translation.continue_assessment}
              </a>

            </Link>

            }
            {this.state.status.toLowerCase() !== 'completed' && this.state.status.toLowerCase() !== 'in progress' &&
            <Link
              to={`/assessment/${assessment.assessmentSid}?install=${installSid}&token=${UserStore.token}`}
            >
              <a
                href="/#"
                className="btn btn--outline"
                onClick={(e) => {
                  e.preventDefault();

                  const payload = [{
                    groupItemSid: assessment.sid,
                    userSid: userData.userSid,
                    enrollmentSid: userData.enrollmentSid
                  }];

                  const callback = () => {
                    window.location = (
                      `/assessment/${assessment.assessmentSid}?install=${installSid}&attempt=${AssessmentStore.attemptSid}&token=${UserStore.token}`
                    )
                  };

                  AssessmentStore.createAttempt(payload, callback);
                }}
              >
                {translation.start_assessment}
              </a>
            </Link>
            }
          </div>
        }
      </article>
    )
  }
}

export default translate('AssessmentCard')(AssessmentCard);