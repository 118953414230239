/**
 * Renders the user list
 */

import React from 'react';
import { observer } from 'mobx-react';
import Head from '../common/Head'

// Translation
import translate from '../translate/Translate';

// Helpers
import { checkValidity } from '../../helpers';

// Components
import Button from '../common/Button';

const UserAdd = observer(class UserAdd extends React.Component {
  constructor() {
    super();

    // Create refs
    this.firstName = React.createRef();
    this.lastName = React.createRef();
    this.email = React.createRef();
    this.password = React.createRef();
  }

  /**
   * Submit the form.
   *
   * @function handleSubmit
   */
  handleSubmit = () => {
    const { store, translation } = this.props;
    const { AppStore, EnrollmentStore } = store;

    const firstName = this.firstName.current.value;
    const lastName = this.lastName.current.value;
    const email = this.email.current.value;
    const password = this.password.current.value;

    // Send the request
    const payload = {
      firstName,
      lastName,
      email,
      password,
      username: email,
      enabled: true
    };

    const callback = () => {
      window.location.href = '../';
      AppStore.toast = translation.success;
      AppStore.toastMod = 'positive';
    }

    EnrollmentStore.createUser(payload, callback);
  }

  /**
   * Cancel user creation.
   *
   * @function handleCancel
   */
  handleCancel = () => {
    window.location.href = ('/users');
  }

  render() {
    const { store, translation } = this.props;
    const { AppStore, UserStore } = store;

    if(!AppStore.loading && !UserStore.userData.is_super_admin) {
      window.location = ('/unauthorized');
    }

    const fields = [{
      content: translation.first_name,
      id: 'user-firstName',
      mod: 'field--half',
      ref: this.firstName,
      type: 'text'
    }, {
      content: translation.last_name,
      id: 'user-lastName',
      mod: 'field--half',
      ref: this.lastName,
      type: 'text'
    }, {
      content: translation.email,
      id: 'user-email',
      ref: this.email,
      type: 'email'
    }, {
      content: translation.password,
      id: 'user-password',
      minLength: 6,
      ref: this.password,
      type: 'password'
    }];

    if(!AppStore.loading) {
      return (
        <main
          id="main-content"
          className="page"
          tabIndex="-1"
          aria-labelledby="page-title"
        >
          <Head>
            <title>{translation && translation.heading} | SmarterMeasure</title>
          </Head>

          <form
            autoComplete="off"
            className="form panel panel--solo panel--md"
            noValidate
            onReset={this.handleCancel}
            onSubmit={e => checkValidity(e, this.handleSubmit)}
          >
            <legend>
              <h1 id="page-title" className="panel__title">{translation && translation.heading}</h1>
            </legend>

            <ul className="form__fields">
              {fields.map((field, i) =>
                <li
                  className={field.mod || ''}
                  key={i}
                >
                  <label
                    htmlFor={field.id}
                  >
                    {field.content.label}
                  </label>

                  {field.content.helper &&
                    <span className="form__hint">
                      {field.content.helper}
                    </span>
                  }

                  <input
                    aria-required="true"
                    autoComplete="new-password"
                    data-errormsg={field.content.error}
                    id={field.id}
                    minLength={field.minLength || null}
                    ref={field.ref}
                    required
                    type={field.type}
                  />
                </li>
              )}
            </ul>

            <div className="panel__action">
              <Button
                className="btn btn--outline"
                type="reset"
              >
                {translation.cancel}
              </Button>

              <Button
                className="btn"
                type="submit"
              >
                {translation.submit}
              </Button>
            </div>
          </form>
        </main>
      )
    }

    return null;
  }
})

export default translate('UserAdd')(UserAdd);
