import React from 'react';
import { observer } from 'mobx-react';
import Head from '../common/Head'

// Translation
import translate from '../translate/Translate';

// Components
import Tooltip from '../common/Tooltip';
import Intro from './LRIIntro';
import Summary from './LRISummary';
import LifeFactors from './LRILifeFactors';
import Button from '../common/Button';

// Icons
import * as icons from '../ui/Icons';

const LRIReport = observer(class LRIReport extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      section: 'intro'
    }
  }

  componentDidMount() {
    if(window.location.hash) {
      this.handleNav(window.location.hash.replace('#', ''));
    }
  }

  handleNav(section) {
    this.setState({
      section
    });
  }

  render() {
    const { section } = this.state;
    const pages = [
      {
        title: 'Introduction',
        id: 'intro'
      }, {
        title: 'Summary',
        id: 'summary'
      }, {
        title: 'Life Factors',
        id: 'life-factors'
      }
    ];

    return (
      <main
        id="main-content"
        className="page"
        tabIndex="-1"
        aria-labelledby="page-title"
      >
        <Head>
          <title>LRI Report | SmarterMeasure</title>
        </Head>

        <div className="wrap">
          <div className="page__content">
            <section className="panel page__primary">
              {section === 'intro' &&
                <Intro {...this.props} />
              }

              {section === 'summary' &&
                <Summary {...this.props} />
              }

              {section === 'life-factors' &&
                <LifeFactors {...this.props} />
              }
            </section>

            <div className="page__secondary">
              <nav className="subnav subnav--sticky">
                <ul className="subnav__list">
                  {pages.map(
                    (page, i) =>
                    <li key={i}>
                      <a
                        className={`subnav__item${
                          section === page.id ? ' subnav__item--active' : ' '
                        }`}
                        href={`#${page.id}`}
                        onClick={(e) => this.handleNav(e.target.href.split('#')[1])}
                      >
                        {page.title}
                      </a>
                    </li>
                  )}
                </ul>

                <ul className="subnav__tools toolbar toolbar--inline">
                  <li>
                    <Button
                      className="btn--reset toolbar__btn"
                      type="button"
                      onClick={null}
                    >
                      <icons.print />
                      <Tooltip
                        content="Print report"
                        id="report-print"
                        brief
                        hideLabel
                      />
                    </Button>
                  </li>
                  <li>
                    <Button
                      className="btn--reset toolbar__btn"
                      type="button"
                      onClick={null}
                    >
                      <icons.envelope />
                      <Tooltip
                        content="Email report"
                        id="report-email"
                        brief
                        hideLabel
                      />
                    </Button>
                  </li>
                  <li>
                    <Button
                      className="btn--reset toolbar__btn"
                      type="button"
                      onClick={null}
                    >
                      <icons.graph />
                      <Tooltip
                        content="Analytics"
                        id="report-email"
                        brief
                        hideLabel
                      />
                    </Button>
                  </li>
                  <li>
                    <Button
                      className="btn--reset toolbar__btn"
                      type="button"
                      onClick={null}
                    >
                      <icons.journal />
                      <Tooltip
                        content="Educator report"
                        id="report-email"
                        brief
                        hideLabel
                      />
                    </Button>
                  </li>
                </ul>

                <aside className="panel panel--compact color-key">
                  <h2 className="color-key__title">Color Key</h2>

                  <ol className="color-key__list">
                    <li>
                      <strong className="score-tag score-tag--low">1</strong>
                      <span className="color-key__lbl">Opportunity for Improvement</span>
                    </li>
                    <li>
                      <strong className="score-tag score-tag--mid-low">2</strong>
                    </li>
                    <li>
                      <strong className="score-tag score-tag--mid-high">3</strong>
                    </li>
                    <li>
                      <strong className="score-tag score-tag--high">4</strong>
                      <span className="color-key__lbl">Strength</span>
                    </li>
                  </ol>
                </aside>
              </nav>
            </div>
          </div>
        </div>
      </main>
    )
  }
})

export default translate('LRIReport')(LRIReport);