/**
 * Renders a list of scoring algorithms
 */

import React from 'react';

// Translation
import translate from '../translate/Translate';

// Icons
import * as icons from '../ui/Icons';

// Components
import EditCustomAlgorithms from '../forms/EditCustomAlgorithms';
import Button from '../common/Button';

export const ScoringAlgorithms = class ScoringAlgorithms extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      algorithm: null,
      algorithmIndex: -1,
      editing: false
    }
  }

  handleDelete(i) {
    const { onDelete, store, translation } = this.props;
    const { AppStore } = store;

    const callback = () => {
      AppStore.toast = translation.deleted;
    }

    AppStore.showDialog(
      translation.delete_confirm,
      'confirm',
      () => onDelete(i, callback)
    );
  }

  renderNewBtn() {
    const { translation } = this.props;

    return (
      <Button
        className="btn btn--outline btn--center"
        type="button"
        onClick={() => this.setState({ editing: true })}
      >
        {translation.new_algorithm}
      </Button>
    )
  }

  render() {
    const {
      algorithms,
      assessment,
      onSubmit,
      tags,
      translation
    } = this.props;
    const {
      algorithm,
      algorithmIndex,
      editing
    } = this.state;

    if(editing) {
      return (
        <EditCustomAlgorithms
          {...this.props}
          algorithm={algorithm}
          algorithmIndex={algorithmIndex}
          assessment={assessment}
          onClose={() => this.setState({
            algorithm: null,
            algorithmIndex: -1,
            editing: false
          })}
          onSubmit={onSubmit}
          tags={tags}
        />
      )
    } else {
      return (
        <div>
          {(!algorithms || algorithms.length === 0) &&
            <span className="no-results">
              {translation.no_results}
            </span>
          }

          {algorithms && algorithms.length > 0 &&
            <ul className="grid grid--wrap" data-columns="3">
              {algorithms.map(
                (scoringAlgorithm, i) =>
                <li
                  className="grid__item"
                  key={i}
                >
                  <article
                    className="panel panel--card"
                  >
                    <h2 className="panel__title panel__title--alt">
                      <Button
                        className="btn--title"
                        type="button"
                        onClick={() => this.setState({
                          algorithm: scoringAlgorithm,
                          algorithmIndex: i,
                          editing: true 
                        })}
                      >
                        {scoringAlgorithm.variable}
                      </Button>
                    </h2>

                    <Button
                      className="btn btn--ghost btn--negative panel__remove"
                      type="button"
                      onClick={() => this.handleDelete(i)}
                    >
                      <icons.times />
                      <span className="meta">
                        {translation.delete_algorithm}
                      </span>
                    </Button>

                    {scoringAlgorithm.description &&
                      <p>{scoringAlgorithm.description}</p>
                    }
                  </article>
                </li>
              )}
            </ul>
          }

          {this.renderNewBtn()}
        </div>
      )
    }
  }
}

export default translate('ScoringAlgorithms')(ScoringAlgorithms);
