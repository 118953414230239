/**
 * Renders a form to create an assessment group
 */

import React from 'react';

// Helpers
import { checkValidity } from '../../helpers';

//Components
import Button from '../common/Button';

// Translation
import translate from '../translate/Translate';
import Head from "../common/Head";
import Tooltip from "../common/Tooltip";
import Select from "../common/Select";

export const EditAssessmentGroup = class EditAssessmentGroup extends React.Component {
  constructor(props) {
    super(props);

    // Bind this to functions
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);

    // Create refs
    this.nameField = React.createRef();
    this.keyField = React.createRef();
    this.deliveryModeField = React.createRef();
    this.state = {
      brandSid: '',
      deliveryMode: this.props.store.AssessmentStore?.assessmentGroup?.deliveryMode || 'disaggregate'
    }
  }

  componentDidMount() {
    const { assessmentGroupSid, translation } = this.props;
    const { AppStore } = this.props.store;

    if(!assessmentGroupSid) {
      AppStore.drawerTitle = translation.heading;
    } else {
      AppStore.drawerTitle = translation.heading_edit;
    }
  }

  setOptions() {
    const { assessmentBrands } = this.props.store.AssessmentStore;

    const options = assessmentBrands.map(brand => {
      return {
        label: brand.name,
        value: brand.sid
      }
    })

    // Allow for no brand to be set
    options.unshift({label: 'None', value: ''})

    return options;
  }

  handleSelect(e, value) {
    this.setState({brandSid: value});
  }

  handleDefaultVal() {
    const { assessmentGroup } = this.props;

    if (assessmentGroup && assessmentGroup.branding) {
      return assessmentGroup.branding.sid
    } else return '';
  }

  handleSubmit() {
    const { assessmentGroupSid, store, translation } = this.props;
    const { AppStore, AssessmentStore } = store;

    const payload = {
      name: this.nameField.current.value
    };

    if(this.keyField.current.value) {
      payload.key = this.keyField.current.value;
    }

    // When using our custom select all falsy values except 0 and an empty string
    // will trigger the select to use the label as a value instead of the falsy value.
    // We want to be able to pass null through though so we use '' and change it to null here
    if (this.state.brandSid) {
      payload.brandSid = this.state.brandSid;
    } else if (this.state.brandSid === '') {
      payload.brandSid = null;
    }

    payload.deliveryMode = this.state.deliveryMode;

    const callback = () => {
      AppStore.closeDrawer();

      if(!assessmentGroupSid) {
        AppStore.toast = translation.success;
      } else {
        AppStore.toast = translation.success_edit;
      }

      AssessmentStore.listAssessmentGroups({expand: 'branding'});
    }

    if(!assessmentGroupSid) {
      // Creating a new assessment group
      AssessmentStore.createAssessmentGroup(payload, callback);
    } else {
      // Updating an existing assessment group
      AssessmentStore.updateAssessmentGroup(assessmentGroupSid, payload, callback);
    }
  }

  render() {
    const { assessmentGroupSid, store, translation } = this.props;
    const { AppStore, AssessmentStore } = store;
    const { assessmentGroup } = AssessmentStore;

    const fields = [
      {
        id: 'group-name',
        ref: this.nameField,
        translation: translation.name,
        type: 'text',
        required: true,
        defaultValue: assessmentGroup ? assessmentGroup.name : ''
      }, {
        id: 'group-key',
        ref: this.keyField,
        translation: translation.key,
        type: 'text',
        required: false,
        defaultValue: assessmentGroup ? assessmentGroup.key : ''
      }
    ];

    if(!translation) return;
    return (
      <form
        className="form panel panel--md panel--solo"
        noValidate
        onSubmit={(e) => checkValidity(e, this.handleSubmit)}
        onReset={AppStore.closeDrawer}
      >
        <Head>
          {!assessmentGroupSid &&
            <title>{translation.heading} | SmarterMeasure</title>
          }

          {assessmentGroupSid &&
            <title>{translation.heading_edit} | SmarterMeasure</title>
          }
        </Head>

        <fieldset classNmae = "overflow__hidden">
          <ul className="form__fields">
            {fields.map(
              (field, i) =>
              <li key={i}>
                <label
                  className={`form__lbl${
                    field.required ? ' required' : ''
                  }`}
                  htmlFor={field.id}
                >
                  {field.translation.label}

                  { field.translation.tooltipText &&
                  <Tooltip
                      content={field.translation.tooltipText}
                      className="cplt-label-tooltip"
                      right
                  />
                  }
                </label>

                <input
                  type={field.type}
                  id={field.id}
                  required={field.required ? true : false}
                  aria-required={field.required ? 'true' : 'false'}
                  data-errormsg={field.translation.error}
                  ref={field.ref}
                  defaultValue={field.defaultValue}
                  placeholder={field.translation.placeholder}
                />
              </li>
            )}
            <li>
              <label
                className='form__lbl'
                htmlFor='assessment-branding'
              >
                {translation.assessment_branding}
              </label>
              <Select
                id='assessment-branding'
                onChange={this.handleSelect}
                options={this.setOptions()}
                defaultValue={this.handleDefaultVal()}
              />
            </li>
            <li>
              <label>
                Delivery Mode
              </label>
              <div className='column'>

                <label>
                  <input
                    type = 'radio'
                    checked = {this.state.deliveryMode === 'disaggregate'}
                    onChange={() => this.setState({deliveryMode:'disaggregate'})}
                  />
                  {translation.deliveryMode.disaggregate.label}
                  <Tooltip
                    content={translation.deliveryMode.disaggregate.tooltipText}
                    className="cplt-label-tooltip wide"
                    wide
                  />
                </label>

                <label>
                  <input
                    type = 'radio'
                    checked = {this.state.deliveryMode === 'sequential_disaggregate'}
                    onChange={() => this.setState({deliveryMode:'sequential_disaggregate'})}
                  />
                  {translation.deliveryMode.sequential_disaggregate.label}
                  <Tooltip
                    content={translation.deliveryMode.sequential_disaggregate.tooltipText}
                    className="cplt-label-tooltip wide"
                    wide
                  />
                </label>

                <label>
                  <input
                    type = 'radio'
                    checked = {this.state.deliveryMode === 'sequential'}
                    onChange={() => this.setState({deliveryMode:'sequential'})}

                  />
                  {translation.deliveryMode.sequential.label}
                  <Tooltip
                    content={translation.deliveryMode.sequential.tooltipText}
                    className="cplt-label-tooltip"
                    wide


                  />
                </label>
              </div>
            </li>
          </ul>
        </fieldset>


        <div className="panel__action">
          <Button
            className="btn btn--outline"
            type="reset"
          >
            {translation.cancel}
          </Button>

          <Button
            className="btn"
            type="submit"
          >
            {translation.submit}
          </Button>
        </div>
      </form>
    )
  }
}

export default translate('EditAssessmentGroup')(EditAssessmentGroup);
