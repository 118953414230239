/**
 * Renders a datepicker dialog popup
 *
 * Derived from W3C's WAI-ARIA Datepicker Dialog Example
 * Source: https://www.w3.org/TR/wai-aria-practices-1.1/examples/dialog-modal/datepicker-dialog.html
 */

import React from 'react';

// Translation
import translate from '../translate/Translate';

// Helpers
import { isDescendant, isSameDate, isNotSameMonth } from '../../helpers';

// Icons
import * as icons from '../ui/Icons';

// Components
import Button from './Button';

const keyCode = {
  'TAB': 9,
  'ENTER': 13,
  'ESC': 27,
  'SPACE': 32,
  'PAGEUP': 33,
  'PAGEDOWN': 34,
  'END': 35,
  'HOME': 36,
  'LEFT': 37,
  'UP': 38,
  'RIGHT': 39,
  'DOWN': 40
};

const DatePicker = class DatePicker extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      open: false,
      focusDate: null,
      selectedDate: null,
      lastMessage: '',
      cells: []
    };

    // Create refs
    this.control = React.createRef();
    this.input = React.createRef();
    this.dialog = React.createRef();
    this.prevYear = React.createRef();
    this.prevMonth = React.createRef();
    this.nextMonth = React.createRef();
    this.nextYear = React.createRef();
    this.monthYear = React.createRef();
    this.tbody = React.createRef();
    this.selectButton = React.createRef();
    this.closeButton = React.createRef();
    this.message = React.createRef();

    this._months = [];
    this._isMounted = false;
  }

  componentDidMount() {
    const { defaultDate, translation } = this.props;

    this._months = translation.months;
    const date = this.getNearestActiveDate();

    this.setState({
      focusDate: new Date(date),
      selectedDate: defaultDate ? new Date(date) : null
    }, () => {
      this.initCells();
      if (defaultDate) {
        this.setTextboxDate(date);
      }
    });

    document.body.addEventListener('click', this.handleBackgroundClick);
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  initCells = () => {
    const { htmlId } = this.props;

    let index = 0;
    let cells = [];

    for (var i = 0; i < 6; i++) {
      for (var j = 0; j < 7; j++) {
        const cell = {
          index,
          htmlId: `id-date-btn-${htmlId}-${index}`,
          row: i,
          column: j,
          date: new Date(),
          disabled: false,
          selected: false
        }
        cells.push(cell);
        index++;
      }
    }

    this.setState({
      cells
    }, () => {
      this.updateGrid();
    });
  }

  handleChange = () => {
    const { id, onChange } = this.props;
    const date = this.state.selectedDate ?
      new Date(this.state.selectedDate)
      : null;

    if(onChange) {
      const value = date ? date.toISOString() : null;
      onChange(id, value);
    }
  }

  /**
   * Update the dates displayed inside the calendar dialog
   *
   * @param {boolean} focus - Optional flag to apply focus after updating
   */
  updateGrid = (focus) => {
    var i, flag;
    var fd = new Date(this.getNearestActiveDate(this.state.focusDate));
    this.monthYear.current.innerHTML = this._months[fd.getMonth()] + ' ' + fd.getFullYear();
    var firstDayOfMonth = new Date(fd.getFullYear(), fd.getMonth(), 1);
    var dayOfWeek = firstDayOfMonth.getDay();
    firstDayOfMonth.setDate(firstDayOfMonth.getDate() - dayOfWeek);
    var d = new Date(firstDayOfMonth);

    let cells = [];

    for (i = 0; i < this.state.cells.length; i++) {
      flag = isNotSameMonth(fd, d);
      const newCell = this.updateCell(flag, d);
      cells.push({
        ...this.state.cells[i],
        ...newCell
      });

      d.setDate(d.getDate() + 1); // Increase the date by 1 while iterating
    }

    this.setState({
      cells
    }, () => {
      if (focus) {
        this.applyFocus();
      }
    });
  };

  /**
   * Apply keyboard focus to the focusDate element.
   */
  applyFocus = () => {
    const { focusDate } = this.state;

    this.state.cells.forEach((cell) => {
      const element = document.getElementById(cell.htmlId);
      element.tabIndex = '-1';
      if (isSameDate(cell.date, focusDate)) {
        element.tabIndex = '0';
        element.focus();
      }
    })
  }

  /**
   * Update the value of the current focusDate, & update the grid
   * when the focusDate goes beyond current grid.
   *
   * @param {Date} date - JavaScript Date object
   */
  updateFocusDate = (date) => {
    const { min, max, translation } = this.props;
    const fd = new Date(this.state.focusDate);
    let changed = !isSameDate(fd, date);
    let updateGrid = false;

    if (isNotSameMonth(fd, date)) {
      updateGrid = true;
    }

    // Check first if it's out of bounds of min/max
    if (this.isPastMin(date)) {
      date = this.props.min;
      changed = true;
      updateGrid = true;
      const friendlyMin = this.formatFriendlyDateString(min.getFullYear(), min.getMonth(), min.getDate());
      this.setMessage(translation.min_help.replace('%date%', friendlyMin));
    }
    if (this.isPastMax(date)) {
      date = this.props.max;
      changed = true;
      updateGrid = true;
      const friendlyMax = this.formatFriendlyDateString(max.getFullYear(), max.getMonth(), max.getDate());
      this.setMessage(translation.max_help.replace('%date%', friendlyMax));
    }

    this.setState({
      focusDate: date
    }, () => {
      if (changed) {
        if (updateGrid) {
          this.updateGrid(true);
        } else {
          this.applyFocus();
        }
      }
    });
  };

  /**
   * Show the calendar dialog
   */
  show = () => {
    const updateGrid = true;

    this.setState({
      open: true
    }, () => {
      this.getDateInput(updateGrid);
    });
  }

  /**
   * Hide the calendar dialog
   */
  hide = () => {
    this.setState({
      open: false
    }, () => {
      this.input.current.focus();
    });
  }

  /**
   * Clear the textbox of its input, optional flag to hide the dialog
   *
   * @param {boolean} hide
   */
  clear = (hide) => {
    if (this.state.selectedDate !== null) {
      this.setDate();
    } else {
      this.setTextboxDate(null);
    }
    if (hide) {
      this.hide();
    }
    this.setState({
      focusDate: this.getNearestActiveDate()
    });
  }

  /**
   * Close the dialog when a user clicks outside it
   */
  handleBackgroundClick = (event) => {
    if (
      this.state.open &&
      !isDescendant(this.dialog.current, event.target) &&
      !isDescendant(this.control.current, event.target) &&
      this._isMounted
    ) {
      this.hide();
    }
  }

  handleSelectButton = (event) => {
    let flag = false;

    switch (event.type) {
      case 'keydown':

        switch (event.keyCode) {
          case keyCode.ENTER:
            this.setDate(this.state.focusDate);
            this.hide();
            flag = true;
            break;

          case keyCode.TAB:
            if (!event.shiftKey) {
              this.prevYear.current.focus();
              flag = true;
            }
            break;

          case keyCode.ESC:
            this.hide();
            flag = true;
            break;

          default:
            break;

        }
        break;

      case 'click':
        this.setDate(this.state.focusDate);
        this.hide();
        flag = true;
        break;

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  handleCloseButton = (event) => {
    let flag = false;

    switch (event.type) {
      case 'keydown':

        switch (event.keyCode) {
          case keyCode.ENTER:
            this.hide();
            flag = true;
            break;

          case keyCode.ESC:
            this.hide();
            flag = true;
            break;

          default:
            break;

        }
        break;

      case 'click':
        this.hide();
        flag = true;
        break;

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  handleClearButton = (event) => {
    let flag = false;
    const hide = true;

    switch (event.type) {
      case 'keydown':

        switch (event.keyCode) {
          case keyCode.ENTER:
            this.clear(hide);
            flag = true;
            break;

          case keyCode.ESC:
            this.hide();
            flag = true;
            break;

          default:
            break;

        }
        break;

      case 'click':
        this.clear(hide);
        flag = true;
        break;

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  handleNextYearButton = (event) => {
    let flag = false;

    switch (event.type) {
      case 'keydown':

        switch (event.keyCode) {
          case keyCode.ESC:
            this.hide();
            flag = true;
            break;

          case keyCode.ENTER:
            this.moveToNextYear();
            flag = true;
            break;

          default:
            break;
        }

        break;

      case 'click':
        this.moveToNextYear();
        break;

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  handlePreviousYearButton = (event) => {
    let flag = false;

    switch (event.type) {

      case 'keydown':

        switch (event.keyCode) {

          case keyCode.ENTER:
            this.moveToPreviousYear();
            flag = true;
            break;

          case keyCode.TAB:
            if (event.shiftKey) {
              this.selectButton.current.focus();
              flag = true;
            }
            break;

          case keyCode.ESC:
            this.hide();
            flag = true;
            break;

          default:
            break;
        }

        break;

      case 'click':
        this.moveToPreviousYear();
        break;

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  handleNextMonthButton = (event) => {
    let flag = false;

    switch (event.type) {

      case 'keydown':

        switch (event.keyCode) {
          case keyCode.ESC:
            this.hide();
            flag = true;
            break;

          case keyCode.ENTER:
            this.moveToNextMonth();
            flag = true;
            break;

          default:
            break;
        }

        break;

      case 'click':
        this.moveToNextMonth();
        break;

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  handlePreviousMonthButton = (event) => {
    let flag = false;

    switch (event.type) {

      case 'keydown':

        switch (event.keyCode) {
          case keyCode.ESC:
            this.hide();
            flag = true;
            break;

          case keyCode.ENTER:
            this.moveToPreviousMonth();
            flag = true;
            break;

          default:
            break;
        }

        break;

      case 'click':
        this.moveToPreviousMonth();
        flag = true;
        break;

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  moveToNextYear = () => {
    const fd = new Date(this.state.focusDate);
    const newDate = new Date(fd.setFullYear(fd.getFullYear() + 1));
    this.updateFocusDate(newDate);
  };

  moveToPreviousYear = () => {
    const fd = new Date(this.state.focusDate);
    const newDate = new Date(fd.setFullYear(fd.getFullYear() - 1));
    this.updateFocusDate(newDate);
  };

  moveToNextMonth = () => {
    const fd = new Date(this.state.focusDate);
    let newDate;

    // If current focus is on a day that doesn't exist next month,
    // Set focus to the last numerical date available.
    if (fd.getDate() === 31 ||
        (fd.getMonth() === 0 && fd.getDate() === 30)) {

      // Calculate the first day of two months ahead.
      const int_date = new Date(fd.getFullYear(), fd.getMonth() + 2, 1);
      // Then calculate one day prior to that intermediary date.
      newDate = new Date(int_date - 1);
    } else {
      newDate = new Date(fd.setMonth(fd.getMonth() + 1));
    }

    this.updateFocusDate(newDate);
  };

  moveToPreviousMonth = () => {
    const fd = new Date(this.state.focusDate);
    let newDate;

    // If current focus is on a day that doesn't exist in the previous
    // month, set focus to the last numerical date available.
    if (fd.getDate() === 31 ||
        (fd.getMonth() === 2 && fd.getDate() === 30) ){

      // Calculate the first day of the current month.
      const int_date = new Date(fd.getFullYear(), fd.getMonth(), 1);
      // Then calculate one day prior to that intermediary date.
      newDate = new Date(int_date - 1);
    } else {
      newDate = new Date(fd.setMonth(fd.getMonth() - 1));
    }

    this.updateFocusDate(newDate);
  };

  moveFocusToNextDay = () => {
    const fd = new Date(this.state.focusDate);
    const newDate = new Date(fd.setDate(fd.getDate() + 1));
    this.updateFocusDate(newDate);
  };

  moveFocusToNextWeek = () => {
    const fd = new Date(this.state.focusDate);
    const newDate = new Date(fd.setDate(fd.getDate() + 7));
    this.updateFocusDate(newDate);
  };

  moveFocusToPreviousDay = () => {
    const fd = new Date(this.state.focusDate);
    const newDate = new Date(fd.setDate(fd.getDate() - 1));
    this.updateFocusDate(newDate);
  };

  moveFocusToPreviousWeek = () => {
    const fd = new Date(this.state.focusDate);
    const newDate = new Date(fd.setDate(fd.getDate() - 7));
    this.updateFocusDate(newDate);
  };

  moveFocusToFirstDayOfWeek = () => {
    const fd = new Date(this.state.focusDate);
    const newDate = new Date(fd.setDate(fd.getDate() - fd.getDay()));
    this.updateFocusDate(newDate);
  };

  moveFocusToLastDayOfWeek = () => {
    const fd = new Date(this.state.focusDate);
    const newDate = new Date(fd.setDate(fd.getDate() + (6 - fd.getDay())));
    this.updateFocusDate(newDate);
  };

  handleTextboxBlur = () => {
    this.getDateInput();
  }

  /**
   * Display a date in the textbox in MM/DD/YYYY format
   *
   * @param {Date} date - Optional - Date to display in textbox
   */
  setTextboxDate = (date) => {
    if (date) {
      const value = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
      this.input.current.value = value;
    } else {
      this.input.current.value = '';
    }
  };

  isPastMin = (date) => {
    const { min } = this.props;

    return min && date < min;
  }

  isPastMax = (date) => {
    const { max } = this.props;

    return max && date > max;
  }

  getNearestActiveDate = (date) => {
    const { defaultDate } = this.props;
    let nearestActive = date || defaultDate || new Date();

    if (this.isPastMin(nearestActive)) {
      return this.props.min;
    }
    if (this.isPastMax(nearestActive)) {
      return this.props.max;
    }

    return nearestActive;
  }

  /**
   * Update focusDate and selectedDate with user input from the textbox
   * @param {boolean} flag - Flag to optionally re-render Grid
   */
  getDateInput = (flag) => {
    var parts = this.input.current.value.split('/');

    if ((parts.length === 3) &&
        Number.isInteger(parseInt(parts[0])) &&
        Number.isInteger(parseInt(parts[1])) &&
        Number.isInteger(parseInt(parts[2]))) {

      const date = new Date(parseInt(parts[2]), parseInt(parts[0]) - 1, parseInt(parts[1]));

      /**
       * Clear textbox if date input is outside of min/max bounds.
       */
      if (this.isPastMin(date) || this.isPastMax(date)) {
        this.clear();
      } else {
        if (!this.state.selectedDate || !isSameDate(date, this.state.selectedDate)) {
          this.setDate(date);
        }
      }
    }
    else {
      /**
       * If not a valid date MM/DD/YYYY, clear the textbox.
       */
      this.clear();
    }

    if (flag) {
      this.updateGrid(true);
    }
  };

  /**
   * Format a human-readable date string for announcements via assistive technology.
   *
   * @param {number} year
   * @param {number} month
   * @param {number} day
   */
  formatFriendlyDateString = (year, month, day) => {
    let selectedDate = null;

    if (typeof year !== 'number' || typeof month !== 'number' || typeof day !== 'number') {
      selectedDate = new Date(this.state.focusDate);
    }
    else {
      selectedDate = new Date(year, month, day);
    }

    const dayLabels = this.props.translation.days.map((day) => day.abbr);
    let label = dayLabels[selectedDate.getDay()];
    label += ' ' + this.props.translation.months[selectedDate.getMonth()];
    label += ' ' + (selectedDate.getDate());
    label += ', ' + selectedDate.getFullYear();

    return label;
  };

  /**
   * Set a visually hidden, 'aria-live' announcement for screen readers
   * and other assistive technology
   *
   * @param {string} str - Message to announce to the user
   */
  setMessage = (str) => {
    function setMessageDelayed () {
      this.setState({
        lastMessage: str
      });
    }

    if (str !== this.state.lastMessage) {
      setTimeout(setMessageDelayed.bind(this), 200);
    }
  };

  /**
   * Control button functionality
   */
  handleControlKeyDown = (event) => {
    let flag = false;

    switch (event.keyCode) {

      case keyCode.SPACE:
      case keyCode.ENTER:
        this.show();
        flag = true;
        break;

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  handleControlClick = (event) => {
    if (!this.state.open) {
      this.show();
    }
    else {
      this.hide();
    }

    event.stopPropagation();
    event.preventDefault();
  }

  /**
   * Apply a dynamic aria-label to the control button
   *
   * @param {string} str
   */
  setLabel = (str) => {
    if (typeof str === 'string' && str.length) {
      str = ', ' + str;
    }
    this.control.current.setAttribute('aria-label', this.props.translation.choose + str);
  }

  /**
   * Set the value of the user's date selection & handle change.
   *
   * @param {Date} date
   */
  setDate = (date) => {
    let focusDate;
    let selectedDate;

    if (date) {
      focusDate = new Date(date);
      selectedDate = new Date(date);
      this.setTextboxDate(date);
    } else {
      const fallback = this.getNearestActiveDate();

      focusDate = new Date(fallback);
      selectedDate = null;
      this.setTextboxDate(null);
    }

    this.setState({
      focusDate,
      selectedDate
    }, () => {
      this.handleChange();
    });
  }

  /**
   * Returns a string value for the control button's aria-label
   */
  getDateLabel = () => {
    let label = '';

    const parts = this.input.current.value.split('/');

    if ((parts.length === 3) &&
        Number.isInteger(parseInt(parts[0])) &&
        Number.isInteger(parseInt(parts[1])) &&
        Number.isInteger(parseInt(parts[2]))) {
      const month = parseInt(parts[0]) - 1;
      const day = parseInt(parts[1]);
      const year = parseInt(parts[2]);

      label = this.formatFriendlyDateString(year, month, day);
    }

    return label;
  }

  handleControlFocus = () => {
    const dateLabel = this.getDateLabel();

    if (dateLabel) {
      this.setLabel('selected date is ' + dateLabel);
    }
    else {
      this.setLabel('');
    }
  }

  /**
   * Update data within the scope of a single day cell
   *
   * @param {boolean} disabled - Is this date on the calendar disabled?
   * @param {Date} date - JS Date object of the date in the calendar cell
   */
  updateCell = (disabled, date) => {
    const newDate = new Date(date);

    var d = newDate.getDate().toString();
    if (newDate.getDate() <= 9) {
      d = '0' + d;
    }

    var m = newDate.getMonth() + 1;
    if (newDate.getMonth() < 9) {
      m = '0' + m;
    }

    var full = newDate.getFullYear() + '-' + m + '-' + d;

    // Update the value of 'disabled' with respect to min & max:
    if (this.isPastMin(date) || this.isPastMax(date)) {
      disabled = true;
    }

    return {
      date: newDate,
      dataFormat: full,
      disabled
    }
  };

  handleDayMouseDown = (event, cell) => {
    const { disabled, date } = cell;

    if (!disabled) {
      this.setDate(date);
      this.hide();
    }

    event.stopPropagation();
    event.preventDefault();
  }

  handleDayKeyDown = (event, cell) => {
    let flag = false;

    switch (event.keyCode) {

      case keyCode.ESC:
        this.hide();
        break;

      case keyCode.TAB:
        this.closeButton.current.focus();
        if (event.shiftKey) {
          this.nextYear.current.focus();
        }
        flag = true;
        break;

      case keyCode.ENTER:
      case keyCode.SPACE:
        this.setDate(cell.date);
        this.hide();
        flag = true;
        break;

      case keyCode.RIGHT:
        this.moveFocusToNextDay();
        flag = true;
        break;

      case keyCode.LEFT:
        this.moveFocusToPreviousDay();
        flag = true;
        break;

      case keyCode.DOWN:
        this.moveFocusToNextWeek();
        flag = true;
        break;

      case keyCode.UP:
        this.moveFocusToPreviousWeek();
        flag = true;
        break;

      case keyCode.PAGEUP:
        if (event.shiftKey) {
          this.moveToPreviousYear();
        }
        else {
          this.moveToPreviousMonth();
        }
        this.updateFocusDate(cell.date);
        flag = true;
        break;

      case keyCode.PAGEDOWN:
        if (event.shiftKey) {
          this.moveToNextYear();
        }
        else {
          this.moveToNextMonth();
        }
        this.updateFocusDate(cell.date);
        flag = true;
        break;

      case keyCode.HOME:
        this.moveFocusToFirstDayOfWeek();
        flag = true;
        break;

      case keyCode.END:
        this.moveFocusToLastDayOfWeek();
        flag = true;
        break;
      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  handleDayFocus = () => {
    const { cursor_help } = this.props.translation;
    this.setMessage(cursor_help);
  }

  /**
   * Render a 6x7 grid of daypicker button cells
   */
  renderGrid = () => {
    let rows = [];

    for (let i = 0; i < 6; i++) {
      rows.push(<tr key={i}>
        {this.state.cells.map(
          //eslint-disable-next-line
          (cell, j) => {
            if (cell.row === i) {
              return (
                <td key={j} className="datepicker__date-cell">
                  <Button
                    id={cell.htmlId}
                    type="button"
                    className="datepicker__date-btn"
                    tabIndex="-1"
                    onMouseDown={(e) => this.handleDayMouseDown(e, cell)}
                    onKeyDown={(e) => this.handleDayKeyDown(e, cell)}
                    onFocus={this.handleDayFocus}
                    {...cell.disabled && {
                      disabled: true,
                      'aria-disabled': true
                    }}
                  >
                    {cell.date.getDate()}
                  </Button>
                </td>
              )
            }
          })}
      </tr>)
    }

    return rows;
  }

  render() {
    const { htmlId, translation, label } = this.props;

    return (
      <div
        id={`id-datepicker--${htmlId}`}
        className="datepicker"
      >
        <label
          htmlFor={`id-datepicker-input--${htmlId}`}
          className="datepicker__lbl form__lbl"
        >
          {label || "Date"}
        </label>

        <div className="datepicker__textbox">
          <input
            id={`id-datepicker-input--${htmlId}`}
            className="datepicker__input"
            type="text"
            ref={this.input}
            placeholder="mm/dd/yyyy"
            aria-autocomplete="none"
            onBlur={this.handleTextboxBlur}
          />

          <Button
            id={`id-datepicker-control--${htmlId}`}
            className="datepicker__control"
            type="button"
            refValue={this.control}
            aria-label={translation.choose}
            onClick={this.handleControlClick}
            onKeyDown={this.handleControlKeyDown}
            onFocus={this.handleControlFocus}
          >
            <icons.calendar />
          </Button>
        </div>

        <section
          id={`id-datepicker-dialog--$${htmlId}`}
          className={`datepicker__dialog${
            this.state.open ? ' datepicker__dialog--visible' : ''
          }`}
          ref={this.dialog}
          role="dialog"
          aria-modal="true"
          aria-labelledby={`id-datepicker-heading--${htmlId}`}
        >
          <header className="datepicker__dialog-head">
            <h2
              id={`id-datepicker-heading--${htmlId}`}
              ref={this.monthYear}
              className="datepicker__month"
              aria-live="polite"
            >
              Month Year
            </h2>

            <ul className="datepicker__nav">
              <li>
                <Button
                  reValuef={this.prevYear}
                  type="button"
                  className="datepicker__nav-btn datepicker__nav-btn--prev-year"
                  aria-label={translation.prev_year}
                  onClick={this.handlePreviousYearButton}
                  onKeyDown={this.handlePreviousYearButton}
                >
                  <icons.chevronLeftDouble />
                </Button>
              </li>
              <li>
                <Button
                  refValue={this.prevMonth}
                  type="button"
                  className="datepicker__nav-btn datepicker__nav-btn--prev-month"
                  aria-label={translation.prev_month}
                  onClick={this.handlePreviousMonthButton}
                  onKeyDown={this.handlePreviousMonthButton}
                >
                  <icons.chevronLeft />
                </Button>
              </li>
              <li>
                <Button
                  refValue={this.nextMonth}
                  type="button"
                  className="datepicker__nav-btn datepicker__nav-btn--next-month"
                  aria-label={translation.next_month}
                  onClick={this.handleNextMonthButton}
                  onKeyDown={this.handleNextMonthButton}
                >
                  <icons.chevronRight />
                </Button>
              </li>
              <li>
                <Button
                  refValue={this.nextYear}
                  type="button"
                  className="datepicker__nav-btn datepicker__nav-btn--next-year"
                  aria-label={translation.next_year}
                  onClick={this.handleNextYearButton}
                  onKeyDown={this.handleNextYearButton}
                >
                  <icons.chevronRightDouble />
                </Button>
              </li>
            </ul>
          </header>

          <table
            id={`id-datepicker-grid--${htmlId}`}
            className="datepicker__grid"
            role="grid"
            aria-labelledby={`id-datepicker-heading--${htmlId}`}
          >
            <thead>
              <tr>
                {translation.days.map(
                  (day, i) =>
                  <th key={i} scope="col" abbr={day.abbr}>
                    {day.text}
                  </th>
                )}
              </tr>
            </thead>
            <tbody ref={this.tbody}>
              {this.renderGrid()}
            </tbody>
          </table>

          <div className="datepicker__action">
            <Button
              className="btn btn--ghost"
              type="button"
              refValue={this.closeButton}
              value="close"
              onClick={this.handleCloseButton}
              onKeyDown={this.handleCloseButton}
            >
              {translation.close}
            </Button>

            <Button
              className="btn btn--ghost"
              type="button"
              refValue={this.clearButton}
              value="clear"
              onClick={this.handleClearButton}
              onKeyDown={this.handleClearButton}
            >
              {translation.clear}
            </Button>

            <Button
              type="button"
              refValue={this.selectButton}
              className="btn btn--ghost"
              value="select"
              onClick={this.handleSelectButton}
              onKeyDown={this.handleSelectButton}
            >
              {translation.select}
            </Button>
          </div>

          <div
            refValue={this.message}
            className="message meta"
            aria-live="polite"
          >
            {this.state.lastMessage}
          </div>
        </section>
      </div>
    )
  }
}

export default translate('DatePicker')(DatePicker);
