// Import React
import React from 'react';

// Helpers
import { getUrlSegment } from './app/helpers';

// Import dependencies
import { render } from 'react-dom';

// Import styles
import './css/screen.css'

// Import components
import App from './app/components/App';

// Import stores
import store from './app/stores';

// List of paths that don’t require TTL check
const noTtlUrls = ['session-expired', 'sign-in', 'unauthorized', 'group', 'config-error'];

// Main app component
const app = <App skipTtl={noTtlUrls.indexOf(getUrlSegment(1)) > -1} store={store} />

// Root DOM element
const rootEl = document.querySelector('#app');

render(app, rootEl);
