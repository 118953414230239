/**
 * Renders a tab list and tab panels
 */

import React from 'react';
import {Link} from 'react-router-dom';

// Components
import Button from './Button';

const Tabs = class Tabs extends React.Component {
  constructor(props) {
    super(props);

    // Set the initial state
    this.state = {
      activeTab: this.props.activeTab || 0
    }
  }

  componentDidMount() {
    const { onMount } = this.props;

    if(onMount) {
      onMount();
    }
  }

  componentDidUpdate() {
    const { activeTab } = this.props;

    if(activeTab !== this.state.activeTab) {
      this.setState({
        activeTab
      });
    }
  }

  render() {
    const {
      className,
      onClick,
      tabContents,
      tabs
    } = this.props;

    return (
      <div className={`tab-wrap${
        className ? ` ${className}` : ''
      }`}>
        <ul className="tab-list">
          {tabs.map(
            (tab, i) =>
            <li key={i}>
              {!tab.url &&
                <Button
                  className={`tab ${i === this.state.activeTab ? 'tab--active' : ''}`}
                  onClick={() => {
                    if(onClick) {
                      onClick(i);
                    }

                    this.setState({ activeTab: i });
                  }}
                >
                  {tab.title}
                </Button>
              }

              {tab.url &&
                <Link
                  to={tab.url}
                >
                  <a
                    href="#/"
                    className={`tab ${i === this.state.activeTab ? 'tab--active' : ''}`}
                    title={tab.title}
                    onClick={() => this.setState({ activeTab: i })}
                  >
                    {tab.title}
                  </a>
                </Link>
              }
            </li>
          )}
        </ul>

        {tabContents && tabContents.map(
          (tabContent, i) => {
            if(i === this.state.activeTab) {
              return (
                <div className="tab-panel" key={i}>
                  {tabContent}
                </div>
              )
            }

            return null;
          }
        )}
      </div>
    )
  }
}

export default Tabs;