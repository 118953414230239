import React from 'react';
import { observer } from 'mobx-react';

// Translation
import translate from '../translate/Translate';

// Components
import Accessibility from './Accessibility';
import Nav from './Nav';

const Header = observer(class Header extends React.Component {
  render() {
    const {
      hideHeader,
      hideNav,
      translation,
      store
    } = this.props;
    const { BrandStore } = store;
    const { brandColors /*, logoUrl, logoAltText*/ } = BrandStore;

    if(!hideHeader) {
      return (
        <header className="app-head" style={{background: brandColors.primaryColor}}>
          <a
            className="app-head__skip"
            href="#main-content"
            onClick={(e) => {
              e.preventDefault();
              document.querySelector('#main-content').focus();
            }}
          >
            {translation.skip_to_content}
          </a>
          <div className="wrap">
            <div className="app-head__tools">
              {/**
                 * TEMP: Hard-coded logo for WGU. Eventually we'll use the API
                 * to handle this.
                 * Logo is optional. If absent, set translation logo value to false.
                 * TODO: This also needs to be changed to use the logoUrl and logoAltText 
                 * once we have set up WGU's data to come through like everything else
                 */}
              {translation.logo &&
                <img
                  className="app-head__brand"
                  src={translation.logo_src}
                  alt={translation.logo_alt}
                />
              }

              <Accessibility
                {...this.props}
              />
            </div>

            {!hideNav &&
              <a
                className="app-head__menu"
                href="#primary-nav"
                onClick={(e) => {
                  e.preventDefault();
                  document.querySelector('.app-head').focus();
                  document.body.classList.toggle('show-nav');
                }}
              >
                {translation.menu}
              </a>
            }

            {!hideNav &&
              <Nav
                {...this.props}
              />
            }
          </div>
        </header>
      )
    } else {
      return (
        <Accessibility
          {...this.props}
        />
      )
    }
  }
})

export default translate('Header')(Header);