/**
 * Renders a modal for assessmentGroup surveys
 */

 import React from 'react';
 import { observer } from 'mobx-react';
 import he from 'he';

 // Translation
 import translate from '../translate/Translate';

 // Icons
 import * as icons from '../ui/Icons';
 // Survey
 import * as Survey from 'survey-react';

 // Custom Survey Questions
import {
  readingQuestion,
  typingQuestion,
  likertQuestion,
    multiTextInput
} from '../../customSurveyQuestions';

import ActivityStore from "../../stores/ActivityStore";

// Components
import Button from '../common/Button';

export const SurveyModal = observer(class SurveyModal extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      isCompleted: false,
      heartbeatSid: null,
    };
    this.interval = React.createRef();

  }

  async componentDidMount() {

    const heartbeatSid = await ActivityStore.postHeartbeat('Survey Skipped');

    this.setState({heartbeatSid: heartbeatSid});

    this.interval.current = setInterval(() => {
        ActivityStore.sendHeartbeat(heartbeatSid);
    }, 20000)


    ActivityStore.sendActivity('Survey Presented');

  }

  renderSkipBtn = () => {
  const { translation } = this.props
  let nav;

  const saveDelay = setInterval(() => {
    nav = document.querySelector('.sv_nav');
    // Adding new class and then removing original
    // to not have conflicting styles
    nav.classList.add('sv_modal');
    nav.classList.remove('sv_nav');


    if (nav) {
      clearInterval(saveDelay);

      const skipBtn = document.createElement('button');
      skipBtn.textContent = translation.skip_survey;
      skipBtn.className = 'sv_nav_skip';
      // Adding style this way because SurveyJS has a style
      // that overrides ours and this is the workaround
      skipBtn.style.marginRight = '10px';

      skipBtn.onclick = () => {
        this.skipSurvey();
      }

      nav.insertBefore(skipBtn, nav.lastChild);
      // Bring focus to the close btn for accessibility
      document.getElementById('survey_close').focus();
    }
  }, 100);
  }

/* This is the function that is called when the user clicks the skip button. It
checks to see if the survey has been completed and if it has it dismisses the
modal. If it hasn't been completed it sends the survey skipped activity and then
dismisses the modal. */
  skipSurvey = () => {
  const { surveyState, store } = this.props;
  const { ActivityStore, SurveyStore } = store;

  clearInterval(this.interval.current);
  ActivityStore.patchExecuteFalse(this.state.heartbeatSid);

  if (this.state.isCompleted) {
      // Only dismiss modal if survey is completed already
      surveyState();
  } else if(!this.state.isCompleted) {
    // Sends the survey skipped activity and then dismisses the modal
    ActivityStore.sendActivity('Survey Skipped');
    SurveyStore.sendSurveyData(null);
    surveyState();
  }
  }

  onComplete = (surveyResponse) => {
  const { ActivityStore, SurveyStore } = this.props.store;

  const responseJson = {};

  Object.keys(surveyResponse.data).forEach(question => {
    if(surveyResponse.data[question]?.secondsElapsed) {
      responseJson[question] = surveyResponse.data[question];
    } else {
      responseJson[question] = {

        response: surveyResponse.data[question]
      };
    }
  });

  ActivityStore.sendActivity('Survey Completed');
  SurveyStore.sendSurveyData(responseJson);
  // Sets state for the modal so the close
  // button won't mark the survey as skipped when used after completing survey
    this.setState({
      isCompleted: true
    })
  }

  render() {
    const { surveyJson, store, translation } = this.props;
    const { SurveyStore } = store;



    // Custom question types
    typingQuestion(Survey);
    readingQuestion(Survey);
    likertQuestion(Survey);
    multiTextInput(Survey);

    // Define model passed from parent component
    const model = new Survey.Model(surveyJson);


    // Handle custom rendering
    model.onAfterRenderQuestion.add((survey, options) => {
      const questionTitle = options.htmlElement.querySelector('h5');

      let questionText = null;
      let questionNumber = null;
      let textString = null;

      if(questionTitle) {
        questionText = questionTitle.querySelector('span:not([class])');
        questionNumber = questionTitle.querySelector('.sv_q_num');
        questionNumber.innerText += '.';
      }

      if(questionText) {
        // Remove all HTML encoding from the question.

        textString = he.decode(questionText.innerHTML);
      }

      // Parse any HTML in the question title
      if(questionText && textString) {
        questionText.innerHTML = textString;
      }

      // Modify the markup of the question title
      // because SurveyJS’s markup is just astounding
      if(questionTitle) {
        const question = options.htmlElement;
        question.className += ' question';

        const questionHead = options.htmlElement.querySelector(':scope > div:first-child');
        questionHead.className += ' question__head';

        questionTitle.outerHTML = ` 
          <legend class="${questionTitle.className} question__title"></legend>
        `;

        const questionLabel = options.htmlElement.querySelector('.question__title');
        const questionNumberHeading = document.createElement('h2');
        questionNumberHeading.append(questionNumber);
        questionLabel.append(questionNumberHeading);
        questionLabel.append(questionText);

        question.prepend(questionLabel);

        const questionAnswer = options.htmlElement.querySelector(':scope > div:last-of-type');
        questionAnswer.className += ' question__answer';

        const questionLegend = questionAnswer.querySelector('legend');

        if(questionLegend) {
          questionLegend.remove();
        }

        const fieldset = document.createElement('fieldset');
          fieldset.append(questionLabel);
          fieldset.append(questionHead);
          fieldset.append(questionAnswer);

          question.append(fieldset);

        // Check for any empty headings
          // which SurveyJS loves to add
          const headings = document.querySelectorAll('h3, h5');

          headings.forEach(heading => {
            if(!heading.textContent) {
              heading.remove();
            }
          })

      }

      // Hide the Likert style question's placeholder. Should only be
      // removed from the Assessment view, not the Survey creator, because
      // otherwise it breaks the Likert rendering in Survey creator.
      const likertPlaceholder = document.querySelectorAll('.likert-placeholder');
      likertPlaceholder.forEach(placeholder => (placeholder.style.display = 'none'));
    });

    return (
      <div className="dialog__overlay">
        <aside className="dialog">
          <div className="dialog__survey">
            <header className="dialog__header ">
              <strong className="dialog__title__survey">
                {SurveyStore.name || translation.survey}

                <Button
                  className="dialog__close"
                  id="survey_close"
                  onClick={this.skipSurvey}
                >
                  <icons.times />
                  <span className="meta">
                  {translation.dismiss}
                  </span>
                </Button>
              </strong>
            </header>

            {!this.state.isCompleted &&
            <p>{SurveyStore.detailText}</p>
            }

            {!this.state.isCompleted &&
              <Survey.Survey
                model={model}
                onAfterRenderPage={this.renderSkipBtn}
                showCompletedPage={false}
                onComplete={this.onComplete}
              />
            }

            {this.state.isCompleted &&
            <p>{translation.completed_text}</p>
            }
          </div>
        </aside>
      </div>
    );
  }
})

export default translate('SurveyModal')(SurveyModal);