import { action, observable } from 'mobx';
import { api } from '../config';

// UserStore (used to access user data)
import UserStore from './UserStore';

// AppStore (used for loading, error throwing, etc.)
import AppStore from './AppStore';
import SurveyStore from "./SurveyStore";


let obx = observable({
  /**
   * Sends an activity for a given event name
   *
   * @param {string} eventName - Name of the activity event
   * @param {Object} [metaData] - Any additional data for the activity payload
   */
  sendActivity: action((eventName, metaData = {}) => {
    const { assessmentGroupSid, targetUserData, userData, installSid } = UserStore;

    api
      .post(`/installs/${installSid}/users/${userData.userSid}/activity`, {
        eventName,
        eventTimestamp: new Date().toISOString(),
        applicationSid: userData.smarterMeasure.applicationSid || null,
        applicationInstallSid: userData.installSid,
        metaData: {
          assessmentGroupSid,
          targetUserSid: targetUserData.userSid || userData.userSid,
          ...metaData
        }
      })
      .catch(error => {
        AppStore.throwError(error);
      });
  }),
  postHeartbeat:  action(async (eventName) => {
    const {assessmentGroupSid, targetUserData, userData} = UserStore;
    let surveyData = SurveyStore.returnSurveyMetaData();

    let response = await api.post(`/surveys/heartbeat`, {
      eventName,
      applicationSid: userData.smarterMeasure.applicationSid,
      applicationInstallSid: userData.installSid,
      metaData: {
        type: 'cptSurvey',
        assessmentGroupSid,
        targetUserSid: targetUserData.userSid,
        userSid: userData.userSid,
        surveyData,
      }
    })

    return response.data;

  }),
  sendHeartbeat: action(async(sid) => {
    await api.patch(`/heartbeat/${sid}`)
  }),
  patchExecuteFalse: action((sid) => {
    api.patch(`/heartbeat/${sid}/execute`)
      .then(() => {})
      .catch(error => {
      AppStore.throwError(error)
      })
  })

})

export default obx;