import React from 'react';
import { observer } from 'mobx-react';
import Head from '../common/Head'

// Translation
import translate from '../translate/Translate';

// Components
import ResultList from './ResultList';
import SurveyModal from '../reports/SurveyModal';


const Results = observer(class Results extends React.Component {
  constructor() {
    super();

    this.lastPage = null;

    this.state = {
      isCompleted: false,
      showSurvey: false
    }

  }
  componentDidMount() {
    const { AssessmentStore, UserStore, SurveyStore } = this.props.store;
    const { assessmentGroup, assessmentGroupSid, userData } = UserStore;
    let query = {};
    let surveySid;

    if(userData.role.toLowerCase() === 'learner') {
      surveySid = assessmentGroup.studentSurveySid;
    } else {
      surveySid = assessmentGroup?.facultySurveySid;
    }

    if(surveySid && UserStore.surveysEnabled) {
      SurveyStore.checkForSurvey(surveySid, this.surveyState);
    }

    if(userData.role.toLowerCase() === 'learner') {
      // Show all completed attempts for students
      query = {
        assessmentGroupSid,
        completionState: 'latest-complete',
        userSid: userData.userSid
      };

      // Callback that loops through all results and
      // gets previous attempts for the same assessment.
      // This is necessary because there is no endpoint
      // that returns user attempts grouped by assessment
      // and ordered by date.
      const callback = (results) => {
        results.forEach(attempt => {
          AssessmentStore.listAttempts(
            {
              groupItemSid: attempt.groupItemSid,
              completionState: 'other-complete',
              userSid: userData.userSid
            },
            `previousAttempts-${attempt.attemptSid}`
          );
        });
      }

      AssessmentStore.listAttempts(query, null, callback);
    } else {
      // Show the latest completed attempts for instructors
      query = {
        completionState: 'latest-complete'
      };

      if(UserStore.targetUserId) {
        query.userSid = UserStore.targetUserData.userSid;
      }

      AssessmentStore.listAttempts(query);
    }
  }

  componentDidUpdate() {
    if (this.state.showSurvey) {
      // This is to disable scrolling while the modal is active.
      document.body.style.overflow = 'hidden';
    } else if (!this.state.showSurvey) {
      // Allows scrolling again
      document.body.style.overflow = '';
    }
   }


    // Used to set state from child component
    surveyState = () => {
      this.setState({
        showSurvey: !this.state.showSurvey
      });
    }



  render() {
    const { store, translation } = this.props;
    const { AssessmentStore, UserStore, SurveyStore } = store;
    const { attempts } = AssessmentStore;
    const { signOnData, targetUserData } = UserStore;
    const { role } = UserStore.userData;

    return (
      <main
        id="main-content"
        className="page"
        tabIndex="-1"
        aria-labelledby="page-title"
      >
        <Head>
          <title>{translation.heading} | SmarterMeasure</title>
        </Head>

        <div className="wrap">
          <header className="page__head page__head--alt">
            <h1 id="page-title" className="page__title">
              {translation.heading}
            </h1>

            {role.toLowerCase() !== 'learner' && targetUserData.firstName &&
              <h2 className="page__subtitle">
                {targetUserData.firstName} {targetUserData.lastName}
              </h2>
            }

            {role.toLowerCase() !== 'learner' && signOnData &&
              <span className="page__tag">
                {translation.user_id.replace('%uid%', UserStore.signOnData.data.customTargetUserId)}
              </span>
            }
          </header>

          <ResultList
            {...this.props}
            attempts={attempts}
          />

          {this.state.showSurvey && UserStore.surveysEnabled &&

            <SurveyModal
              {...this.props}
              surveyJson={SurveyStore.surveyJson}
              surveyState={this.surveyState}
              focusFirstQuestionAutomatic={false}
              showCompletedPage={false}
            />
          }
        </div>
      </main>
    )
  }
})

export default translate('Results')(Results);