/**
 * Renders the SurveyJS creator
 */

import React from 'react';
import Head from '../common/Head'
import { toJS } from 'mobx';


// Translation
import translate from '../translate/Translate';

// Components
import Tabs from '../common/Tabs';
import History from '../views/SurveyHistory';
import Tooltip from '../common/Tooltip';
import SurveyDesigner from "./SurveyDesigner";
import SurveyDetails from "./SurveyDetails";
import Button from '../common/Button';

// Icons
import * as icons from '../ui/Icons';

export const EditSurvey = class EditSurvey extends React.Component {
    constructor() {
        super();

        // Create model for new surveys to use
        this.newSurvey= {
            surveyJson: {
                pages: null,
            },
            description: null,
            name: null,
            key:null,
            triggerJson: {
                daysUntilNextSurvey: null,
                pageViewsBeforeInitialSurvey: null
            }
        }
        // Set the initial state
        this.state = {
            activeTabIndex: 0,
            survey:null,
            setupComplete: false,
            surveySid: null
        };

        this.handleDelete = this.handleDelete.bind(this);
        this.renderToolbar = this.renderToolbar.bind(this);
    }

    async componentDidMount() {
        const { surveySid, translation } = this.props;
        const { AppStore, SurveyStore } = this.props.store;

        if(!surveySid) {
            AppStore.drawerTitle = translation.heading;
        }
        else {
            let survey = await SurveyStore.getSurvey(surveySid);

            if(survey.draftJson){
                AppStore.toast = translation.draft.heading
                survey.surveyJson = survey.draftJson.surveyJson || null;
                survey.triggerJson = survey.draftJson.triggerJson || null;
                survey.description = survey.draftJson.description || null;
                survey.name = survey.draftJson.name || null;
            }

            AppStore.drawerTitle = survey?.name || translation.edit.heading;

            survey = toJS(survey);

            this.setState({
                survey
            });
        }
    }

    componentWillUnmount() {
        const { SurveyStore } = this.props.store;

        SurveyStore.survey = null;
    }

    /**
     * handleDelete - Deletes the assessment
     */
    handleDelete() {
        const { surveySid, store, translation } = this.props;
        const { AppStore, SurveyStore } = store;

        const callback = () => {
            SurveyStore.listSurveys();
            AppStore.closeDrawer();
            AppStore.toast = translation.delete.success;
        }

        AppStore.showDialog(
            translation.delete_confirm,
            'confirm',
            () => SurveyStore.deleteSurvey(this.state.surveySid || surveySid, callback)
        );
    }

    /**
     * handleUpdate - Creates a new survey version and updates existing survey
     * @param {object} survey - survey data that will become the new version
     */
    handleUpdate(survey){
        const{surveySid, store, translation} = this.props
        const {AppStore, SurveyStore} = store;

        const callback = () => {
            SurveyStore.listSurveys();
            AppStore.toast = translation.edit.success;
        }

        SurveyStore.createSurveyVersion(this.state.surveySid || surveySid, survey, callback)

    }

    /**
     * restoreVersion - Restores a previous version of an survey draft
     * @param {object} survey - The old survey version that will become the draft
     * @param {function} callback - function to be called after api call
     */
    restoreVersion = (survey, callback) => {
        this.setState({
            survey
        }, () => {
            // Make an API call to save the restored draft
            this.handlePartialUpdate(callback);

            this.setState({
                activeTabIndex: 0
            });
        });
    }

    /**
     * handlePartialUpdate - Updates part of an assessment
     * (used for scoring algorithms, tag aliases, and cutpoints)
     * @param {function} callback
     * @param {function} errorCallback
     * @param {boolean} background (hides loading indicator)
     */
    handlePartialUpdate(callback, errorCallback, background) {
        const { store } = this.props;
        const { SurveyStore } = store;

        let surveySid;

        if(this.state.surveySid) {
            surveySid = this.state.surveySid;
        } else if(this.props.surveySid) {
            surveySid = this.props.surveySid;
        }

        const surveyJson = this.state.survey ? this.state.survey.surveyJson : this.props.survey.surveyJson;
        const name = this.state.survey ? this.state.survey.name : this.props.survey.name;
        const detailText = this.state.survey ? this.state.survey.description : this.props.survey.description;
        const key = this.state.survey ? this.state.survey.key : this.props.survey.key;

        const payload = {
            surveyJson,
            detailText,
            key,
            name
        };

        // eslint-disable-next-line
        Object.keys(payload).map(key => {
            if(!payload[key]) {
                delete payload[key]
            };
        });

        SurveyStore.restoreVersion(surveySid, payload, callback, errorCallback, background);
    }
    /**
     * handleDraft - updates the draftJson of a survey
     * @param {object} survey - The old survey version that will become the draft
     * @param {boolean} submit (pulls surveys for updated results)
     */
    handleDraft(survey, submit = false){
        const{surveySid, store } = this.props
        const {SurveyStore} = store;

        SurveyStore.handleDraft(surveySid ? surveySid : this.state.surveySid, survey)
        if(submit === true){
            SurveyStore.listSurveys()
        }
    }


    renderToolbar() {
        const { store, translation, newSurvey } = this.props;
        const { AppStore } = store;

        // Add drawer toolbar
        const toolbar = [
            <Button
                className="btn btn--ghost btn--negative"
                onClick={this.handleDelete}
                key="delete"
            >
                <Tooltip
                    content={translation.delete.button}
                    icon={<icons.trash />}
                    id="assessment-delete"
                    brief
                    down
                    hideLabel
                />
            </Button>,
            <Button
                className="btn btn--ghost"
                onClick={() => {
                    this.handleDraft(newSurvey ? this.newSurvey : this.state.survey, true);
                }}
                key="save"
            >
                <Tooltip
                    content={translation.save_draft.button}
                    icon={<icons.disk />}
                    id="assessment-save"
                    brief
                    down
                    hideLabel
                />
            </Button>,
            <Button
                className="btn btn--ghost"
                onClick={() => this.handleUpdate(newSurvey ? this.newSurvey : this.state.survey)}
                key="publish"
            >
                <icons.publish key="icon" />
            </Button>
        ];

        AppStore.drawerToolbar = toolbar;
    }

    render() {
        const { surveySid, newSurvey, translation } = this.props;
        const { activeTabIndex, survey, setupComplete} = this.state;

        const tabs = [
            {
                title: 'Designer'
            }, {
                title: 'Details'
            },  {
                title: 'History'
            }
        ]

        const tabContents = [

            <SurveyDesigner
                {...this.props}
                survey={newSurvey ? this.newSurvey : survey}
                onUpdate={(survey) => {
                    this.handleDraft(newSurvey ? this.newSurvey : survey);
                    if(!newSurvey){
                        this.setState({survey: survey})
                    }
                }}
            />,
            <SurveyDetails
            onUpdate={(survey) => {
                if (this.props.newSurvey) {
                    this.newSurvey = survey;
                    this.handleDraft(this.newSurvey)
                } else {
                    this.setState(survey)
                    this.handleDraft(this.state.survey)
                }
            }}
            {...this.props}
            survey={this.props.newSurvey ? this.newSurvey : survey}
            setupComplete={this.state.setupComplete}
        />,
            <History
                {...this.props}
                surveySid={surveySid || this.state.surveySid}
                onRestore={this.restoreVersion}
            />

        ];

        if(newSurvey && !setupComplete) {
            return (
            <SurveyDetails
            onUpdate={(survey) => {
                if (this.props.newSurvey) {
                    this.newSurvey = survey;
                } else {
                    this.setState({
                        survey: survey
                    })
                }
            }}
            {...this.props}
            survey={this.props.newSurvey ? this.newSurvey : survey}
            setupComplete={setupComplete}
            parent={this}
            />
            )
        } else if((surveySid && survey) || !survey) {
            return (
                <div>
                    <Head>
                        {!surveySid &&
                        <title>{translation.heading} | SmarterMeasure</title>}

                        {surveySid && survey &&
                        <title>{survey.name ? survey.name : translation.edit.heading} | SmarterMeasure</title>
                        }
                    </Head>

                    {(newSurvey || this.state.setupComplete || !newSurvey) &&
                    <Tabs
                        {...this.props}
                        activeTab={activeTabIndex}
                        className="drawer__tabs"
                        onClick={(i) => this.setState({ activeTabIndex: i })}
                        onMount={this.renderToolbar}
                        tabContents={tabContents}
                        tabs={tabs}
                    />
                    }

                    {/* Custom icons for SurveyJS question types */}
                    <svg
                        style={{ display: 'none' }}
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            id="icon-book"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11 1H2V13L3 15H4H12H13V4V3H11V1ZM3.5 14L3 13H11V4H12V14H3.5ZM4 4H9V5H4V4ZM5 6H8V7H5V6Z"
                            fill="#3E4D5D"
                        />

                        <path
                            id="icon-keyboard"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2 4H13V11H2V4ZM1 12V3H14V12H1ZM4 5H3V6H4V5ZM6 5H5V6H6V5ZM7 5H8V6H7V5ZM10 5H9V6H10V5ZM11 5H12V6H11V5ZM4 7H3V8H4V7ZM5 7H6V8H5V7ZM8 7H7V8H8V7ZM9 7H10V8H9V7ZM12 7H11V8H12V7ZM5 9H10V10H5V9Z"
                            fill="#3E4D5D"
                        />
                    </svg>
                </div>
            )
        }
        return null;
    }
}

export default translate('EditSurvey')(EditSurvey);
