import { action, observable } from 'mobx';
import {api, config} from '../config';

// Helpers
import {formatQuery, getParam, getUrlSegment, toBase64} from '../helpers';

// AppStore (used for loading, error throwing, etc.)
import AppStore from './AppStore';

// UserStore (used to access user data)
import UserStore from './UserStore';

let obx = observable({
  /**
   * createAssessmentGroup
   * @param {object} payload
   * @param {function} callback
   */
  assessmentGroup: null,
  submissionConfirmation: true,

  createAssessmentGroup: action((payload, callback) => {
    AppStore.startLoading('createAssessmentGroup');

    const url = `/installs/${UserStore.installSid}/assessmentGroups`;

    api.post(url, payload)
      .then(() => {
        if(callback) {
          callback();
        }

        AppStore.finishLoading('createAssessmentGroup');
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  }),

  /**
   * updateAssessmentGroup
   * @param {string} assessmentGroupSid
   * @param {object} payload
   * @param {function} callback
   */
  updateAssessmentGroup: action((assessmentGroupSid, payload, callback) => {
    AppStore.startLoading('updateAssessmentGroup');

    const url = `/installs/${UserStore.installSid}/assessmentGroups/${assessmentGroupSid}`;

    api.put(url, payload)
      .then(() => {
        if(callback) {
          callback();
        }

        AppStore.finishLoading('updateAssessmentGroup');
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  }),

  /**
   * deleteAssessmentGroup
   * @param {string} assessmentGroupSid
   * @param {function} callback
   */
  deleteAssessmentGroup: action((assessmentGroupSid, callback) => {
    AppStore.startLoading('deleteAssessmentGroup');

    const url = `/installs/${UserStore.installSid}/assessmentGroups/${assessmentGroupSid}`;

    api.delete(url)
      .then(() => {
        if(callback) {
          callback();
        }

        AppStore.finishLoading('deleteAssessmentGroup');
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  }),

  /**
   * listAssessmentGroups - Gets all assessment groups for an install
   * @param {object} query
   */
  assessmentGroups: [],
  assessmentGroupIndex: {},
  assessmentGroupsNext: null,
  assessmentBrands: [],

  listAssessmentGroups: action((query, append, callback) => {
    AppStore.startLoading('listAssessmentGroups');

    const url = `/installs/${UserStore.installSid}/assessmentGroups${formatQuery(query)}`;

    api.get(url)
      .then(response => {
        if(!append) {
          obx.assessmentGroups = response.data.results;
        } else {
          obx.assessmentGroups = obx.assessmentGroups.concat(response.data.results);
        }

        // Store assessment group names for easy lookup
        response.data.results.forEach(assessmentGroup => {
          obx.assessmentGroupIndex[assessmentGroup.assessmentGroupSid] = assessmentGroup.name
        });

        if(response.data.next) {
          obx.assessmentGroupsNext = response.data.next;
        } else {
          obx.assessmentGroupsNext = null;
        }

        if(callback) {
          callback();
        }

        AppStore.finishLoading('listAssessmentGroups');
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  }),

  listAssessmentBrands: action(() => {
    const url = `/installs/${UserStore.installSid}/assessmentBranding`
    api.get(url)
      .then(response => {
        if (response.data && response.data.results) {
          obx.assessmentBrands = response.data.results;
        }
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  }),

  /**
   * createAssessmentGroupItem - Adds an assessment to an assessment group
   * @param {string} assessmentGroupSid
   * @param {object} payload
   * @param {function} callback
   */
  createAssessmentGroupItem: action((assessmentGroupSid, payload, callback) => {
    AppStore.startLoading('createAssessmentGroupItem');

    const url = `/installs/${UserStore.installSid}/assessmentGroups/${assessmentGroupSid}/items`;

    api.post(url, payload)
      .then(() => {
        if(callback) {
          callback()
        }

        AppStore.finishLoading('createAssessmentGroupItem');
      })
      .catch(error => {
        AppStore.throwError(error);
      });
  }),

  /**
   * createAssessment
   * @param {object} payload
   * @param {function} callback
   */
  createAssessment: action((payload, callback) => {
    AppStore.startLoading('createAssessment');

    const url = `/installs/${UserStore.installSid}/assessments`;

    if(!payload.customAlgorithms) {
      payload.customAlgorithms = [];
    }

    if(!payload.customCutPoint) {
      payload.customCutPoint = [];
    }

    api.post(url, payload)
      .then(response => {
        if(callback) {
          callback(response.data);
        }

        AppStore.finishLoading('createAssessment');
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  }),

  /**
   * updateAssessment
   * @param {string} assessmentSid
   * @param {object} payload
   * @param {function} callback
   */
  updateAssessment: action((assessmentSid, payload, callback, errorCallback, background) => {
    if(!background) {
      AppStore.startLoading('updateAssessment');
    }

    const url = `/installs/${UserStore.installSid}/assessments/${assessmentSid}`;

    if(!payload.customAlgorithms) {
      payload.customAlgorithms = [];
    }

    if(!payload.customCutPoint) {
      payload.customCutPoint = [];
    }

    api.put(url, payload)
      .then(() => {
        if(callback) {
          callback();
        }

        if(!background) {
          AppStore.finishLoading('updateAssessment');
        }
      })
      .catch(error => {
        if(errorCallback) {
          errorCallback();
        }

        AppStore.throwError(error);
      })
  }),

  /**
   * listAssessments - Gets all assessments for an installation
   * @param {object} query
   */
  assessments: [],
  assessmentsNext: null,

  listAssessments: action(async (query, append, callback) => {
    AppStore.startLoading('listAssessments');

    const url = `/installs/${UserStore.installSid}/assessments${formatQuery(query)}`;

    await api.get(url)
      .then(response => {
        if(!append) {
          obx.assessments = response.data.results;
        } else {
          obx.assessments = obx.assessments.concat(response.data.results);
        }

        if(response.data.next) {
          obx.assessmentsNext = response.data.next;
        } else {
          obx.assessmentsNext = null;
        }

        if(callback) {
          callback();
        }

        AppStore.finishLoading('listAssessments');
      })
      .catch(error => {
        AppStore.throwError(error);
      })

    return obx.assessments;
  }),

  /**
   * listGroupAssessments - Gets all assessments for a given group
   * @param {string} assessmentGroupSid
   * @param {object} query
   */
  listGroupAssessments: action(async (assessmentGroupSid, query, callback) => {
    AppStore.startLoading('listGroupAssessments');

    const url = `/installs/${UserStore.installSid}/assessmentGroups/${assessmentGroupSid}${formatQuery(query)}`;

    await api.get(url)
      .then(response => {
        obx.assessments = response.data.items;

        if(callback) {
          callback(response);
        }

        AppStore.finishLoading('listGroupAssessments');
      })
      .catch(error => {
        AppStore.throwError(error);
      })

    return obx.assessments;
  }),

  /**
   * getAssessment
   * @param {string} assessmentSid
   */
  assessment: null,
  getAssessment: action(async (assessmentSid, query, callback) => {

    AppStore.startLoading('getAssessment');

    const url = `/installs/${UserStore.installSid}/assessments/${assessmentSid}${formatQuery(query)}`;

    await api.get(url)
      .then(response => {
        obx.assessment = response.data;

        if(response.data.submissionConfirmation !== null){
          obx.submissionConfirmation = response.data.submissionConfirmation;
        }

        if(callback) {
          callback();
        }

        AppStore.finishLoading('getAssessment');
      })
      .catch(error => {
        AppStore.throwError(error);
      })

    return obx.assessment;
  }),

  getLearnerAssessment: action(async () => {
    let params = '';
    let version = getParam('version')
    let displayMode = getParam('displayMode')
    let token = getParam('token')
    let assessmentSid = getUrlSegment(2)

    if(version) {
      params = `?assessmentVersionSid=${version}`;
    }

    if(displayMode) {
      params.length === 0 ? params += '?' : params += '&';
      params += `displayMode=${displayMode}`
    }

    const url = `${config.apiUrl}/installs/${getParam('install')}/assessments/${assessmentSid}${params}`
    const response = await fetch(url, {
      headers: {
        token: token
      }
    });

    let assessment = await response.json();

    // Base64 encode the assessment data
    // so that it’s not passed as plain json
    //assessment = Buffer.from(JSON.stringify(assessment)).toString('base64');

    obx.assessment = assessment
    return {
      assessment
    };

  }),

  /**
   * publishAssessment
   * @param {string} assessmentSid
   * @param {function} callback
   */
  publishAssessment: action((assessmentSid, callback) => {
    AppStore.startLoading('publishAssessment');

    const url = `/installs/${UserStore.installSid}/assessments/${assessmentSid}/publish`;

    api.post(url, { "userSid": UserStore.userData.userSid })
      .then(() => {
        if(callback) {
          callback();
        }

        AppStore.finishLoading('publishAssessment');
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  }),
    attachSurveyToGroup: action((payload, assessmentGroupSid, callback) => {

        const url = `/installs/${UserStore.installSid}/assessmentGroups/${assessmentGroupSid}/attach`;

        api.put(url, payload)
            .then(response => {
                if(callback) {
                    callback(response.data);
                }
            })
            .catch(error => {
                AppStore.throwError(error);
            })
    }),


  /**
   * deleteAssessment
   * @param {string} assessmentSid
   * @param {function} callback
   */
  deleteAssessment: action((assessmentSid, callback) => {
    AppStore.startLoading('deleteAssessment');

    const url = `/installs/${UserStore.installSid}/assessments/${assessmentSid}`;

    api.delete(url)
      .then(() => {
        if(callback) {
          callback();
        }

        AppStore.finishLoading('deleteAssessment');
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  }),

  /**
   * listAssessmentHistory
   * @param {string} assessmentSid
   * @param {object} query
   * @param {boolean} append
   * @param {function} callback
   */
  assessmentHistory: [],
  assessmentHistoryNext: null,
  assessmentHistoryTotal: 0,

  listAssessmentHistory: action((assessmentSid, query, append, callback) => {
    if(!append) {
      AppStore.startLoading('listAssessmentHistory');
    }

    const url = `/installs/${UserStore.installSid}/assessments/${assessmentSid}/history${formatQuery(query)}`;

    api.get(url)
      .then(response => {
        if(!append) {
          obx.assessmentHistory = response.data.results;
        } else {
          obx.assessmentHistory = obx.assessmentHistory.concat(response.data.results);
        }

        obx.assessmentHistoryTotal = response.data.total;

        if(response.data.next) {
          obx.assessmentHistoryNext = response.data.next;
        } else {
          obx.assessmentHistoryNext = null;
        }

        if(callback) {
          callback(response.data.results);
        }

        if(!append) {
          AppStore.finishLoading('listAssessmentHistory');
        }
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  }),

  /**
   * getVersionDiff
   * @param {string} assessmentSid
   * @param {string} versionSid (the version being compared)
   * @param {string} baseVersionSid (the version being compared against)
   */
  diff: null,

  getVersionDiff: action((assessmentSid, versionSid, baseVersionSid, callback) => {
    AppStore.startLoading('getVersionDiff');

    const url = `/installs/${UserStore.installSid}/assessments/${assessmentSid}?assessmentVersionSid=${versionSid}&review=true`;

    api.get(url)
      .then(response => {
        obx.diff = response.data;

        // Remove unnecessary data from diff version
        delete obx.diff.dateUpdated;
        delete obx.diff.lastPublishedDate;
        delete obx.diff.type;

        if(callback) {
          callback();
        }

        AppStore.finishLoading('getVersionDiff');
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  }),

  /**
   * createAttempt
   * @param {object} payload
   */
  attemptSid: null,

  createAttempt: action((payload, callback) => {
    AppStore.startLoading('createAttempt');

    const url = `/installs/${UserStore.installSid}/attempts`;

    api.post(url, payload)
      .then(response => {
        obx.attemptSid = response.data[0].attemptSid;

        if(callback) {
          callback();
        }

        AppStore.finishLoading('createAttempt');
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  }),

  /**
   * saveAttempt
   * @param {string} attemptSid
   * @param {object} payload
   * @param {function} callback
   */
  saveAttempt: action(async(attemptSid, payload, callback, backgroundLoad) => {
    if(!backgroundLoad) {
      AppStore.startLoading('submitAssessment');
    }

    if (payload.finalSubmission) {
      console.log("Saving Attempt", payload)
    }
    const url = `/installs/${UserStore.installSid}/attempts/${attemptSid}`;

    try{
      let response = await api.put(url, payload)
        if(callback) {
          callback(response.data);
        }
        if(payload.finalSubmission){
          console.log(payload.responseJson)
          console.log("Response:", response)
        }

        if(!backgroundLoad) {
          AppStore.finishLoading('submitAssessment');
        }
    } catch(error)  {
        if(payload.finalSubmission){
          console.log('Error saving attempt', error, attemptSid)
        }
        AppStore.throwError(error);
      }
  }),

  /**
   * listAttempts
   * @param {object} query
   * @param {string} group
   * @param {function} callback
   * @param {boolean} skipStore If true, don’t store result in obx data
   */
  attempts: [],

  listAttempts: action((query, group, callback, skipStore) => {
    AppStore.startLoading('listAttempts');

    if(!query.sortOrder) {
      query.sortOrder = 'DESC'
    }

    const url = `/installs/${UserStore.installSid}/attempts${formatQuery(query)}`;

    api.get(url)
      .then(response => {
        // TEMP: reorder knowledge, confidence, and experience
        const results = response.data.results;

        results.forEach(result => {
          let tags = result?.outcomeJson?.tags;

          if(tags && tags.knowledge && tags.confidence && tags.experience) {
            tags = {
              experience: tags.experience,
              confidence: tags.confidence,
              knowledge: tags.knowledge
            };

            result.outcomeJson.tags = tags;
          }
        });

        if(!group && !skipStore) {
          obx.attempts = results;
        } else {
          obx[group] = results;
        }

        if(callback) {
          callback(response.data.results);
        }

        AppStore.finishLoading('listAttempts');
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  }),

  /**
   * getAttempt
   * @param {string} attemptSid
   */
  attempt: null,

  getAttempt: action(async attemptSid => {
    AppStore.startLoading('getAttempt');

    const url = `/installs/${UserStore.installSid}/attempts/${attemptSid}`;

    await api.get(url)
      .then(response => {
        // TEMP: reorder knowledge, confidence, and experience
        const result = response.data;

        let tags;

        if(result.outcomeJson && result.outcomeJson.tags) {
          tags = result.outcomeJson.tags;
        }

        if(tags && tags.knowledge && tags.confidence && tags.experience) {
          tags = {
            experience: tags.experience,
            confidence: tags.confidence,
            knowledge: tags.knowledge
          };

          result.outcomeJson.tags = tags;
        }

        obx.attempt = result;

        AppStore.finishLoading('getAttempt');
      })
      .catch(error => {
        AppStore.throwError(error);
      })

    return obx.attempt;
  }),


  /**
   * listTemplates
   */
  assessmentTemplates: [],

  listAssessmentTemplates: action(async param => {
    AppStore.startLoading('listAssessmentTemplates');

    const url = `/installs/${UserStore.installSid}/assessmentTemplates`;

    await api.get(url)
      .then(response => {
        obx.assessmentTemplates = response.data.results;
        AppStore.finishLoading('listAssessmentTemplates');
      })
      .catch(error => {
        AppStore.throwError(error);
      })

    return obx.assessmentTemplates;
  }),

  /**
   * parseQti
   * @param {file} file
   */
  qtiImport: null,

  parseQti: action(async (assessmentKey, file, callback) => {
    AppStore.startLoading('parseQti');

    const url = `/installs/${UserStore.installSid}/import/verify`;

    const qtiZipFile = await toBase64(file[0]);

    const payload = {
      assessmentKey,
      qtiZipFile
    }

    api.post(url, payload)
      .then(response => {
        obx.qtiImport = response.data;

        if(callback) {
          callback(response.data);
        }

        AppStore.finishLoading('parseQti');
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  }),

  /**
   * processQti
   */
  qtiResult: null,

  processQti: action(async (payload, file, callback) => {
    AppStore.startLoading('processQti');

    const url = `/installs/${UserStore.installSid}/import/process`;

    const qtiZipFile = await toBase64(file[0]);

    payload.qtiZipFile = qtiZipFile;

    api.post(url, payload)
      .then(response => {
        obx.qtiResult = response.data;

        if(callback) {
          callback();
        }

        AppStore.finishLoading('processQti');
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  }),

  provisionAssessment: action(async (payload, callback) => {
    AppStore.startLoading('provisionAssessment');

    // Temp hardcode installSid
    const installSid = 'AI0e45ec9b3d8c47db98313b10590c8bdb';

    const url = `/installs/${installSid}/provision`;


    api.post(url, payload)
      .then(response => {
        if (response.data.result === 'assessment-provisioned') {
          // redirect to url
          AppStore.finishLoading('provisionAssessment');

          window.location = response.data.redirectUrl;
        } else if (response.data.result === 'user-already-exists') {
          AppStore.finishLoading('provisionAssessment');

          if (callback) {
            callback(response.data.result)
          };
        } else {
          throw new Error('an unexpected error occurred');
        }
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  })
});

export default obx;