/**
 * Renders all assessments
 */

import React from 'react';
import { observer } from 'mobx-react';
import Head from '../common/Head'

// Helpers
import { deformatQuery } from '../../helpers';

// Translation
import translate from '../translate/Translate';

// Components
import AssessmentCard from '../common/AssessmentCard';
import Filter from '../common/Filter';
import EditAssessment from '../forms/EditAssessment';
import ImportAssessment from '../forms/ImportAssessment';
import LoadMore from '../common/LoadMore';
import Button from '../common/Button';

export const AssessmentsList = observer(class AssessmentsList extends React.Component {
  constructor() {
    super();

    // Bind this to functions
    this.listAssessments = this.listAssessments.bind(this);
    this.handleNewAssessment = this.handleNewAssessment.bind(this);
    this.handleImportAssessment = this.handleImportAssessment.bind(this);

    // Default query params
    this.query = {
      limit: 24,
      sortKeys: 'createdDate',
      sortOrder: 'ASC'
    }
  }

  componentDidMount() {
    this.listAssessments();
  }

  listAssessments() {
    const { AssessmentStore } = this.props.store;

    AssessmentStore.listAssessments(this.query);
  }

  handleNewAssessment(e, assessment) {
    e.preventDefault();

    // Update the URL without causing a page load
    // const pathname = getPathname(e.target.href);

    // window.history.pushState(
    //   {},
    //   'edit-assessment',
    //   pathname
    // );

    // Open the assessment edit drawer
    this.handleDrawer('new');
  }

  handleImportAssessment(e) {
   e.preventDefault();
   this.handleDrawer('import');
  }

  handleDrawer(op) {
    const { AppStore, AssessmentStore } = this.props.store;

    switch (op) {
      case 'new':
        AppStore.drawerContent =
        <EditAssessment
          {...this.props}
          component="EditAssessment"
          assessmentSid={AssessmentStore?.assessment?.assessmentSid || null}
          newAssessment={true}
        />;
        break;

      case 'import':
        AppStore.drawerContent =
        <ImportAssessment
          {...this.props}
          component="ImportAssessment"
        />;
        break;

      default:
        break;
    }

    AppStore.drawerMod = 'wide';
  }

  render() {
    const { store, translation } = this.props;
    const { AssessmentStore } = store;
    const { assessments, assessmentsNext } = AssessmentStore;

    return (
      <main
        id="main-content"
        className="page"
        tabIndex="-1"
        aria-labelledby="page-title"
      >
        <Head>
          <title>{translation.heading} | SmarterMeasure</title>
        </Head>

        <Filter
          id="filter"
          filterParams={this.query}
          filterProp="search"
          onClear={this.listAssessments}
          onFilter={AssessmentStore.listAssessments}
          placeholder={translation && translation.filter}
          useApi
        />

        <div className="wrap">
          <header className="page__head">
            <h1 id="page-title" className="page__title">{translation.heading}</h1>
            <div className="btn-group">
              <Button
                className="btn"
                onClick={this.handleImportAssessment}
              >
                {translation.import_assessment}
              </Button>
              <Button
                className="btn"
                onClick={this.handleNewAssessment}
              >
                {translation.new_assessment}
              </Button>
            </div>
          </header>

          {assessments.length > 0 &&
            <ul id="assessments" className="grid grid--wrap" data-columns="3">
              {assessments.map(
                (assessment, i) =>
                <li
                  className="grid__item grid__item--push"
                  key={i}
                >
                  <AssessmentCard
                    {...this.props}
                    assessment={assessment}
                    hideStatus={true}
                    action={'edit'}
                  />
                </li>
              )}
            </ul>
          }

          {assessmentsNext &&
            <LoadMore
              {...this.props}
              buttonText={translation.load_more}
              loadFunction={(callback) => {
                AssessmentStore.listAssessments(deformatQuery(assessmentsNext), true, callback)
              }}
              infinite={true}
            />
          }
        </div>
      </main>
    )
  }
})

export default translate('AssessmentsList')(AssessmentsList);
