/**
 * Renders the SurveyJS creator
 */

import React from 'react';
import Head from '../common/Head'
import { toJS } from 'mobx';

// Helpers
import { removeDuplicates } from '../../helpers';

// Translation
import translate from '../translate/Translate';

// Components
import Tabs from '../common/Tabs';
import EditAssessmentDetails from './EditAssessmentDetails';
import AssessmentDesigner from './AssessmentDesigner';
import ScoringAlgorithms from '../views/ScoringAlgorithms';
import TagAliases from '../views/TagAliases';
import CutPoints from '../views/CutPoints';
import History from '../views/AssessmentHistory';
import Tooltip from '../common/Tooltip';
import Button from '../common/Button';

// Icons
import * as icons from '../ui/Icons';

export const EditAssessment = class EditAssessment extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      activeTabIndex: 0,
      assessment: null,
      tags: [],
      tagsExpanded: []

    };

    // Bind this to functions
    this.handleCreate = this.handleCreate.bind(this);
    this.handlePublish = this.handlePublish.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleScoringAlgorithm = this.handleScoringAlgorithm.bind(this);
    this.deleteScoringAlgorithm = this.deleteScoringAlgorithm.bind(this);
    this.handleTagAlias = this.handleTagAlias.bind(this);
    this.deleteTagAlias = this.deleteTagAlias.bind(this);
    this.handleCutPoint = this.handleCutPoint.bind(this);
    this.deleteCutPoint = this.deleteCutPoint.bind(this);
    this.sanitizeTags = this.sanitizeTags.bind(this);
    this.handlePartialUpdate = this.handlePartialUpdate.bind(this);
    this.renderToolbar = this.renderToolbar.bind(this);
  }

  async componentDidMount() {
    const { assessmentSid,  translation } = this.props;
    const { AppStore, AssessmentStore } = this.props.store;

    if(!assessmentSid) {
      // Creating a new assessment
      AppStore.drawerTitle = translation.heading;
    } else {
      // Editing an existing assessment
      // TODO: Need to get draft, but API returns nothing if there are no drafts.
      // Ideally, the API will return the most recent draft while falling back to published.
      // const query = {
      //   type: 'published'
      // }

      let assessment = await AssessmentStore.getAssessment(assessmentSid);

      AppStore.drawerTitle = assessment?.name || translation.edit.heading;

      assessment = toJS(assessment);

      this.setState({
        assessment
      });

      this.parseTags(assessment);
    }
  }

  componentWillUnmount() {
    const { AssessmentStore } = this.props.store;

    AssessmentStore.assessment = null;
  }

  /**
   *
   * @param {string} tags
   */
  sanitizeTags(tags) {
    // Sanitize the user's input.
    // Add more rules here.

    // Prefix each tag with "tags."
    return tags.map(tag => {
      tag = tag.toLowerCase();
      // Unless it's already prefixed by the user.
      if (tag.substring(0, 4) === "tags.") {
        return tag;
      }
        return "tags." + tag;
    });
  }

  /**
   * parseTags - Loops through assessment JSON to get an array of all tags
   * @param {object} assessment
   */

  // TODO: Might be worth trying to refactor
  // this since it’s got two nested loops
  parseTags(assessment) {
    const { assessmentJson } = assessment;
    let tags = [];

    if(assessmentJson && assessmentJson.pages) {
      assessmentJson.pages.forEach(page => {
        if(page.elements) {
          page.elements.forEach(element => {
            if(element.scoringTags) {
              const tagArr = element.scoringTags.split(',');
              tags = tags.concat(this.sanitizeTags(tagArr));
            }
          });
        }
      });
    }

    // Remove any leading spaces from tag names
    for(let i = 0; i < tags.length; i++) {
      if(tags[i][0] === ' ') {
        tags[i] = tags[i].replace(' ', '');
      }
    }

    // Remove duplicate tags
    tags = removeDuplicates(tags);

    // Loop through each tag and create tags for each .
    tags.forEach(tag => {
      if(tag.indexOf('.')) {
        while(tag.lastIndexOf('.') !== -1) {
          tag = tag.substr(0, tag.lastIndexOf('.'));
          // Do not create a tag if its only content is "tags."
          if(tags.indexOf(tag) === -1 && tag !== "tags") {
            tags.push(tag);
          }
        }
      }
    });

    tags.sort();

    // Add scoring variables to tags
    let tagsExpanded = [...tags];
    const scoreVars = [];

    tagsExpanded.forEach(tag => {
      scoreVars.push(`${tag}.possible`);
      scoreVars.push(`${tag}.earned`);
      scoreVars.push(`${tag}.percentage`);
    });

    tagsExpanded = tagsExpanded.concat(scoreVars);
    tagsExpanded.sort();

    // Add quiz-level variables
    tagsExpanded.push('quiz.possible', 'quiz.earned', 'quiz.percentage');

    this.setState({
      tags,
      tagsExpanded
    });
  }

  /**
   * handleCreate - Creates the assessment
   */
  handleCreate(payload, callback) {
    const { store } = this.props;
    const { AssessmentStore } = store;

    AssessmentStore.createAssessment(payload, callback);
  }

  /**
   * handlePublish - Publishes the assessment
   */
  handlePublish() {
    const { assessmentSid, store, translation } = this.props;
    const { AppStore, AssessmentStore } = store;

    const callback = () => {
      AssessmentStore.listAssessments();
      AppStore.closeDrawer();
      AppStore.toast = translation.publish.success;
    }

    AppStore.showDialog(
      translation.publish_confirm,
      'confirm',
      () => AssessmentStore.publishAssessment(assessmentSid || this.state.assessmentSid, callback)
    )
  }

  /**
   * handleDelete - Deletes the assessment
   */
  handleDelete() {
    const { assessmentSid, store, translation } = this.props;
    const { AppStore, AssessmentStore } = store;

    const callback = () => {
      AssessmentStore.listAssessments();
      AppStore.closeDrawer();
      AppStore.toast = translation.delete.success;
    }

    AppStore.showDialog(
      translation.delete_confirm,
      'confirm',
      () => AssessmentStore.deleteAssessment(assessmentSid, callback)
    );
  }

  /**
   * handleScoringAlgorithm - Handles the creation and updating of scoring algorithms
   * @param {object} algorithm
   * @param {integer} algorithmIndex
   * @param {function} callback
   */
  handleScoringAlgorithm(algorithm, algorithmIndex, callback) {
    const { assessment } = this.state;
    const customAlgorithms = [...this.state.assessment.customAlgorithms];

    if(algorithmIndex === undefined || algorithmIndex === null || algorithmIndex <= -1) {
      // Creating a new algorithm
      customAlgorithms.push(algorithm);
    } else {
      // Updating an existing algorithm
      customAlgorithms[algorithmIndex] = algorithm;
    }

    assessment.customAlgorithms = customAlgorithms;

    this.setState({
      assessment
    }, () => {
      // Make an API call to update the custom algorithms
      this.handlePartialUpdate(callback);
    });
  }

  /**
   * deleteScoringAlgorithm - Removes a scoring algorithm
   * @param {integer} algorithmIndex
   * @param {function} callback
   */
  deleteScoringAlgorithm(algorithmIndex, callback) {
    const { assessment } = this.state;
    const customAlgorithms = [...this.state.assessment.customAlgorithms];

    customAlgorithms.splice(algorithmIndex, 1);

    assessment.customAlgorithms = customAlgorithms;

    this.setState({
      assessment
    }, () => {
      // Make an API call to update the custom algorithms
      this.handlePartialUpdate(callback);
    });
  }

  /**
   * handleTagAlias - Handles the creation and updating of tag aliases
   * @param {object} alias
   * @param {string} aliasKey
   * @param {function} callback
   * @param {string} [originalKey=null] - Used if updating scoring tags
   */
  handleTagAlias(alias, aliasKey, callback, originalKey = null) {
    const { assessment } = this.state;
    const tagAliases = {...this.state.assessment.tagAliases};

    tagAliases[aliasKey] = alias;

    // Delete original key so we don't create duplicates
    if (originalKey !== null) {
      delete tagAliases[originalKey]
    }

    assessment.tagAliases = tagAliases;

    this.setState({
      assessment
    }, () => {
      // Make an API call to update the tag aliases
      this.handlePartialUpdate(callback);
    });
  }

  /**
   * deleteTagAlias - Removes a tag alias
   * @param {string} aliasKey
   * @param {function} callback
   */
  deleteTagAlias(aliasKey, callback) {
    const { assessment } = this.state;
    const tagAliases = {...this.state.assessment.tagAliases};

    delete tagAliases[aliasKey];

    assessment.tagAliases = tagAliases;

    this.setState({
      assessment
    }, () => {
      // Make an API call to update the custom algorithms
      this.handlePartialUpdate(callback);
    });
  }

  /**
   * handleCutPoint - Handles the creation and updating of cut points
   * @param {object} cutPoint
   * @param {integer} cutPointIndex
   * @param {function} callback
   */
  handleCutPoint(cutPoint, cutPointIndex, callback) {
    const { assessment } = this.state;
    const customCutPoint = [...this.state.assessment.customCutPoint];

    // If the API call fails, we need to reset
    // the cut point data to what it was before
    // to avoid trying to send bad data again
    const assessmentFallback = {...this.state.assessment};

    if(cutPointIndex === undefined || cutPointIndex === null || cutPointIndex <= -1) {
      // Creating a new cut point
      customCutPoint.push(cutPoint);
    } else {
      // Updating an existing cut point
      customCutPoint[cutPointIndex] = cutPoint;
    }

    assessment.customCutPoint = customCutPoint;

    const errorCallback = () => {
      // Set the assessment data back
      // to the original if the API
      // call fails
      this.setState({
        assessment: assessmentFallback
      });
    }

    this.setState({
      assessment
    }, () => {
      // Make an API call to update the cut points
      this.handlePartialUpdate(callback, errorCallback);
    });
  }

  /**
   * deleteCutPoint - Removes a cut point
   * @param {integer} cutPointIndex
   * @param {function} callback
   */
  deleteCutPoint(cutPointIndex, callback) {
    const { assessment } = this.state;
    const customCutPoint = [...this.state.assessment.customCutPoint];

    customCutPoint.splice(cutPointIndex, 1);

    assessment.customCutPoint = customCutPoint;

    this.setState({
      assessment
    }, () => {
      // Make an API call to update the custom algorithms
      this.handlePartialUpdate(callback);
    });
  }

  /**
   * restoreVersion - Restores a previous version of an assessment draft
   * @param {object} assessment
   */
  restoreVersion = (assessment, callback) => {
    this.setState({
      assessment
    }, () => {
      // Make an API call to save the restored draft
      this.handlePartialUpdate(callback);

      this.setState({
        activeTabIndex: 0
      });
    });
  }

  /**
   * handlePartialUpdate - Updates part of an assessment
   * (used for scoring algorithms, tag aliases, and cutpoints)
   * @param {function} callback
   * @param {function} errorCallback
   * @param {boolean} background (hides loading indicator)
   */
  handlePartialUpdate(callback, errorCallback, background) {
    const { store } = this.props;
    const { AssessmentStore } = store;

    let assessmentSid;

    if(this.state.assessmentSid) {
      assessmentSid = this.state.assessmentSid;
    } else if(this.props.assessmentSid) {
      assessmentSid = this.props.assessmentSid;
    }

    const assessmentJson = this.state.assessment ? this.state.assessment.assessmentJson : this.props.assessment.assessmentJson;
    const name = this.state.assessment ? this.state.assessment.name : this.props.assessment.name;
    const details = this.state.assessment ? this.state.assessment.details : this.props.assessment.details;
    const key = this.state.assessment ? this.state.assessment.key : this.props.assessment.key;
    const estimatedDuration = this.state.assessment ? this.state.assessment.estimatedDuration : this.props.assessment.estimatedDuration;
    const iconName = this.state.assessment ? this.state.assessment.iconName : this.props.assessment.iconName;

    const submissionConfirmation = this.state.assessment ? this.state.assessment.submissionConfirmation || true : this.props.assessment.submissionConfirmation || true ;

    const payload = {
      submissionConfirmation,
      assessmentJson,
      customAlgorithms: toJS(this.state.assessment.customAlgorithms),
      customCutPoint: toJS(this.state.assessment.customCutPoint),
      details,
      estimatedDuration,
      iconName,
      key,
      name,
      tagAliases: toJS(this.state.assessment.tagAliases)
    };

    //eslint-disable-next-line
    Object.keys(payload).map(key => {
      if(!payload[key] && payload[key] !== false) {
        delete payload[key]
      };
    });


    AssessmentStore.updateAssessment(assessmentSid, payload, callback, errorCallback, background);
  }

  renderToolbar() {
    const { store, translation } = this.props;
    const { AppStore } = store;

    // Add drawer toolbar
    const toolbar = [
      <Button
        className="btn btn--ghost btn--negative"
        onClick={this.handleDelete}
        key="delete"
      >
        <Tooltip
          content={translation.delete.button}
          icon={<icons.trash />}
          id="assessment-delete"
          brief
          down
          hideLabel
        />
      </Button>,
      <Button
        className="btn btn--ghost"
        onClick={() => {
          const callback = () => {
            AppStore.toast = translation.save_draft.success;
          }

          this.handlePartialUpdate(callback);
        }}
        key="save"
      >
        <Tooltip
          content={translation.save_draft.button}
          icon={<icons.disk />}
          id="assessment-save"
          brief
          down
          hideLabel
        />
      </Button>,
      <Button
        className="btn btn--ghost"
        onClick={this.handlePublish}
        key="publish"
      >
        <icons.publish key="icon" />
        {translation.publish.button}
      </Button>
    ];

    AppStore.drawerToolbar = toolbar;
  }

  render() {
    const { assessmentSid, newAssessment, store, translation } = this.props;
    const { activeTabIndex, assessment, tags, tagsExpanded } = this.state;
    const { AppStore, AssessmentStore } = store;

    const tabs = [
      {
        title: translation.tabs.designer
      }, {
        title: translation.tabs.details
      }, {
        title: translation.tabs.algorithms
      }, {
        title: translation.tabs.aliases
      }, {
        title: translation.tabs.cutPoints
      }, {
        title: translation.tabs.history
      }
    ]

    const tabContents = [
      <AssessmentDesigner
        {...this.props}
        assessment={assessment}
        onUpdate={() => {
          this.setState({ assessment });
          this.handlePartialUpdate(null, null, true);
        }}
      />,
      <EditAssessmentDetails
        {...this.props}
        assessment={assessment}
        onUpdate={() => {
          this.setState({
            assessment
          }, () => {
            this.handlePartialUpdate(null, null, true);
          });
        }}
      />,
      <ScoringAlgorithms
        {...this.props}
        algorithms={assessment ? assessment.customAlgorithms : []}
        assessment={assessment}
        onDelete={this.deleteScoringAlgorithm}
        onSubmit={this.handleScoringAlgorithm}
        tags={tagsExpanded}
      />,
      <TagAliases
        {...this.props}
        aliases={assessment ? assessment.tagAliases : {}}
        assessment={assessment}
        onDelete={this.deleteTagAlias}
        onSubmit={this.handleTagAlias}
        tags={tags}
      />,
      <CutPoints
        {...this.props}
        algorithms={assessment ? assessment.customAlgorithms : []}
        assessment={assessment}
        cutPoints={assessment ? assessment.customCutPoint : []}
        onDelete={this.deleteCutPoint}
        onSubmit={this.handleCutPoint}
        tags={tagsExpanded}
      />,
      <History
        {...this.props}
        assessmentSid={assessmentSid || this.state.assessmentSid}
        onRestore={this.restoreVersion}
      />
    ];

    if((assessmentSid && assessment) || !assessmentSid) {
      return (
        <div>
          <Head>
            {!assessmentSid &&
              <title>{translation.heading} | SmarterMeasure</title>
            }

            {assessmentSid &&
              <title>{assessment.name ? assessment.name : translation.edit.heading} | SmarterMeasure</title>
            }
          </Head>

          {newAssessment && !this.state.setupComplete &&
            <EditAssessmentDetails
              {...this.props}
              onClose={AppStore.closeDrawer}
              onSubmit={this.handleCreate}
              onSuccess={(assessment) => {
                AssessmentStore.assessment = assessment;

                this.setState({
                  assessment,
                  assessmentSid: assessment.assessmentSid,
                  setupComplete: true
                });

                AssessmentStore.listAssessments();
              }}
            />
          }

          {(!newAssessment || this.state.setupComplete) &&
            <Tabs
              {...this.props}
              activeTab={activeTabIndex}
              className="drawer__tabs"
              onClick={(i) => this.setState({ activeTabIndex: i })}
              onMount={this.renderToolbar}
              tabContents={tabContents}
              tabs={tabs}
            />
          }

          {/* Custom icons for SurveyJS question types */}
          <svg
            style={{ display: 'none' }}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="icon-book"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11 1H2V13L3 15H4H12H13V4V3H11V1ZM3.5 14L3 13H11V4H12V14H3.5ZM4 4H9V5H4V4ZM5 6H8V7H5V6Z"
              fill="#3E4D5D"
            />

            <path
              id="icon-keyboard"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2 4H13V11H2V4ZM1 12V3H14V12H1ZM4 5H3V6H4V5ZM6 5H5V6H6V5ZM7 5H8V6H7V5ZM10 5H9V6H10V5ZM11 5H12V6H11V5ZM4 7H3V8H4V7ZM5 7H6V8H5V7ZM8 7H7V8H8V7ZM9 7H10V8H9V7ZM12 7H11V8H12V7ZM5 9H10V10H5V9Z"
              fill="#3E4D5D"
            />
          </svg>
        </div>
      )
    }

    return null;
  }
}

export default translate('EditAssessment')(EditAssessment);
