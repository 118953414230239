import { action, observable } from 'mobx';

import { api } from '../config';

import AppStore from './AppStore';
import {formatQuery} from "../helpers";

let obx = observable({
  useCustomBrand: false,
  brandColors: {
    primaryColor: null,
    secondaryColor: null,
    primaryButtonColor: null,
    secondaryButtonColor: null
  },
  logoUrl: null,
  logoAltText: null,
  translationOverrides: {},

  /**
   * getBranding - Gets custom UI styles and translations based on installSid
   * @param {string} installSid
   */
  getBranding: action(async function getBranding(installSid, callback) {
    AppStore.startLoading('getBranding');
    // Get rid of this hardcoded value. How are we getting installSid from the domain?
    const url = `/installs/${installSid}/branding`;

    try {
      const response = await api.get(url);
      if (response.data) {
        const { data } = response;

        obx.brandColors = {
          primaryColor: data.primaryColor || null,
          secondaryColor: data.secondaryColor || null,
          primaryButtonColor: data.primaryButtonColor || null,
          secondaryButtonColor: data.secondaryButtonColor || null
        };
        obx.logoUrl = data.logo || null;
        obx.logoAltText = data.logoAlt || null;
        if (data.labels || true) {
          // Merge custom translation with our own
          AppStore.setTranslationOverride(data.labels);
        }

        if (callback) {
          callback(response.data);
        }
      }
      obx.useCustomBrand = true;
      AppStore.finishLoading('getBranding')
    } catch(error) {
      // Do we care if this fails? This only affects the visuals of the page
      console.error(error);
      AppStore.finishLoading('getBranding');
    }

  }),

  getCalculatedBrandingContent: action(async function getCalculatedBrandingContent(data, callback) {
    AppStore.startLoading('getCalculatedBrandingContent');
    const query = {};
    if (data.context) {
      query.context = data.context;
    }
    if (data.brandSid) {
      query.brandSid = data.brandSid;
    }
    if (data.assessmentGroupSid) {
      query.assessmentGroupSid = data.assessmentGroupSid;
    }
    let url = null;
    if (query.brandSid || query.assessmentGroupSid || query.context) {
      url = `/installs/${data.installSid}/content${formatQuery(query)}`;
    } else {
      url = `/installs/${data.installSid}/content`;
    }

    try {
      const response = await api.get(url);
      if (response.data) {
        const { data } = response;

        if (callback) {
          callback(data);
        }
      }
      AppStore.finishLoading('getCalculatedBrandingContent');
    } catch(error) {
      console.error(error);
      AppStore.finishLoading('getCalculatedBrandingContent');
    }
  })
})

export default obx;