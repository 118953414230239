/**
 * Renders Step 4 of 4 of the QTI Import form
 */

import React from 'react';
import Head from '../common/Head'

// Dependencies
import EditAssessment from './EditAssessment';

// Translation
import translate from '../translate/Translate';

// Components
import Button from '../common/Button';

export const ImportAssessmentConfirm = class ImportAssessmentConfirm extends React.Component {
  async componentDidMount() {
    const { translation, success, error } = this.props;
    const { AppStore } = this.props.store;

    AppStore.drawerTitle = success & !error ? translation.success.heading : translation.error.heading;
  }

  handleEditAssessment(e, assessmentSid) {
    e.preventDefault();

    // Open the assessment edit drawer
    this.handleDrawer(assessmentSid);
  }

  handleDrawer(assessmentSid = null) {
    const { AppStore } = this.props.store;

    AppStore.drawerContent =
      <EditAssessment
        {...this.props}
        assessmentSid={assessmentSid}
        component="EditAssessment"
        draft={true}
      />;

    AppStore.drawerMod = 'full';
  }

  render() {
    const { translation, success, error, store } = this.props;
    const { AssessmentStore } = store;
    const { qtiResult } = AssessmentStore;

    return (
      <div className="panel panel--md panel--solo">
        <Head>
          <title>{success && !error ? translation.success.heading : translation.error.heading} | SmarterMeasure</title>
        </Head>

        {success && !error ?
          <div>
            <p className="note">{translation.success.description}</p>
            <p>{translation.next}</p>
            <div className="btn-group">
              <a
                className="btn"
                href={`/edit-assessment/${qtiResult.assessmentSid}`}
                onClick={(e) => this.handleEditAssessment(e, qtiResult.assessmentSid)}
              >
                {translation.view_assessment}
              </a>

              <Button
                className="btn"
                onClick={this.props.importAnotherFile}
              >
                {translation.import_another}
              </Button>
            </div>
          </div>
        : <div className="note">
            {translation.error.update}
          </div>
        }
      </div>
    )
  }
}

export default translate('ImportAssessmentConfirm')(ImportAssessmentConfirm);