/**
 * Renders a drawer element
 * @property {string} id - The ID attribute for the tooltip element
 * @property {string} content - The tooltip content
 */

import React from 'react';
import { observer } from 'mobx-react';

// Translation
import translate from '../translate/Translate';

// Components
import * as icons from '../ui/Icons';
import Button from './Button';

const Drawer = observer(class Drawer extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      hidden: true
    };

    // Create refs
    this.drawer = React.createRef();
  }

  componentDidMount() {
    document.body.classList.add('overlay-open');

    setTimeout(() => {
      this.setState({
        hidden: false
      });
    });

    // Remove the entering class after
    // the animation has had time to complete
    // and focus the content
    setTimeout(() => {
      this.drawer.current.classList.remove('drawer--hidden');

      document.querySelector('#drawer-content').focus();
    }, 250);
  }

  handleClose(e) {
    const { closeDrawer, drawerContent } = this.props.store.AppStore;
    const {SurveyStore} = this.props.store;

    if (drawerContent.props.component === 'EditSurvey' ) {

      SurveyStore.listSurveys()

    };

    closeDrawer(e, this.drawer);
  }

  render() {
    const { translation } = this.props;
    const {
      drawerContent,
      drawerMod,
      drawerTitle,
      drawerToolbar,
      drawerUrl
    } = this.props.store.AppStore;

    return (
      <aside
        id="drawer-content"
        className={`drawer ${
          this.state.hidden ? ' drawer--hidden' : ''
        }${
          drawerMod ? ` drawer--${drawerMod}` : ''
        }`}
        tabIndex="0"
        aria-labelledby="page-title"
        ref={this.drawer}
      >
        {/* eslint-disable-next-line */}
        <header className="drawer__header" role="text">
          {drawerTitle &&
            <h2 id="page-title" className="drawer__title">
              {drawerTitle}
            </h2>
          }

          {drawerToolbar &&
            <div className="drawer__tools">
              {drawerToolbar}
            </div>
          }

          <Button
            className={`drawer__close ${drawerUrl ? 'drawer__close--frame' : ''}`}
            onClick={(e) => this.handleClose(e)}
          >
            <icons.timesThin />

            <span className="meta">{translation.close}</span>
          </Button>
        </header>

        <div
          className={`drawer__content ${drawerUrl ? 'drawer__content--frame' : ''}`}
        >
          {drawerUrl &&
            <iframe
              className="drawer__frame"
              src={drawerUrl}
              title={drawerTitle}
            />
          }

          {drawerContent &&
            drawerContent
          }
        </div>
      </aside>
    )
  }
})

export default translate('Drawer')(Drawer);