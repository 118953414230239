/**
 * Renders the SurveyJS creator
 */

import React from 'react';

// Translation
import translate from '../translate/Translate';

export const AssessmentDesigner = class AssessmentDesigner extends React.Component {
  constructor() {
    super();

    // Set up the SurveyJS Creator
    this.creator = null;

    // Set the initial state
    this.state = {
      render: false
    }
  }

  componentDidMount() {
    this.loadSurveyBuilder();
  }

  /**
   * Load the survey builder.
   * Note: Because of the way NextJS works and the fact that the SmarterSurveys
   * needs to inject a MathJax script into the DOM, we have to load the package
   * in this kind of weird way.
   *
   * @async
   * @function loadSurveyBuilder
   */
  loadSurveyBuilder = async () => {
    this.SmarterSurveys = (await import('@smarterservices/smartersurveys'));

    this.setState({
      render: true
    });
  }

  /**
   * Update the assessment’s data.
   *
   * @function handleUpdate
   */
  handleUpdate = () => {
    const { assessment, onUpdate, store } = this.props;
    const { AppStore } = store;

    if(this.creator.survey.title) {
      assessment.name = this.creator.survey.title
    };

    if(this.creator.survey.description) {
      assessment.details = this.creator.survey.description;
    }

    // Update the drawer title if it has changed
    if(assessment.name !== AppStore.drawerTitle) {
      AppStore.drawerTitle = assessment.name;
    }

    assessment.assessmentJson = this.creator.JSON;

    onUpdate(assessment);
  }

  /**
   * Submit the assessment’s data to the API.
   *
   * @function handleSubmit
   */
  handleSubmit = () => {
    const { assessmentSid, assessment, store, translation } = this.props;
    const { AppStore, AssessmentStore } = store;

    let name = this.creator.survey.title;

    if(!name && assessment) {
      name = assessment.name;
    }

    let details = this.creator.survey.description;

    if(!details && assessment) {
      details = assessment.details;
    }


    let payload = {};

    if(assessmentSid && assessment) {
      payload = assessment;
    }

    if(!assessment.submissionConfirmation) payload.submissionConfirmation = false;


    payload.assessmentJson = this.creator.JSON;

    if(name) {
      payload.name = name;
    }

    if(details) {
      payload.details = details;
    }

    // The API returns details as null if but
    // it won’t accept null when saving…
    if(payload.details === null) {
      delete payload.details;
    }

    delete payload.assessmentSid;
    delete payload.assessmentVersionSid;
    delete payload.appInstallSid;
    delete payload.dateCreated;
    delete payload.dateUpdated;
    delete payload.key;

    let callback = null;

    if(!assessmentSid) {
      // Creating a new assessment
      callback = (response) => {
        AppStore.toast = assessmentSid ? translation.success_edit : translation.success;
        AssessmentStore.listAssessments();
        AppStore.closeDrawer();
      }

      AssessmentStore.createAssessment(payload, callback);
    } else {
      // Editing an existing assessment
      callback = () => {
        AppStore.toast = assessmentSid ? translation.success_edit : translation.success;
        AssessmentStore.listAssessments();
      }

      AssessmentStore.updateAssessment(assessmentSid, payload, callback);
    }
  }

  render() {
    const { assessment, assessmentSid, onUpdate } = this.props;

    if(this.state.render) {
      if((assessmentSid && assessment) || !assessmentSid) {
        if(assessment.assessmentJson && !assessment.assessmentJson.name && assessment.name) {
          assessment.assessmentJson.name = assessment.name;
        }

        return (
          <div id="create-assessment" className="creator">
            <this.SmarterSurveys.default.SurveyBuilder
              onUpdate={surveyData => {
                assessment.assessmentJson = surveyData;
                onUpdate(assessment);
              }}
              surveyData={assessment.assessmentJson || null}
              surveyNameReadonly
            />
          </div>
        )
      }
    }

    return null;
  }
}

export default translate('AssessmentDesigner')(AssessmentDesigner);
