/**
 * Renders the assessment group view
 */

import React from 'react';
import { observer } from 'mobx-react';
import Head from '../common/Head'

// Helpers
import { deformatQuery } from '../../helpers';

// Translation
import translate from '../translate/Translate';

// Components
import AssessmentGroupCard from '../common/AssessmentGroupCard';
import Filter from '../common/Filter';
import EditAssessmentGroup from '../forms/EditAssessmentGroup';
import LoadMore from '../common/LoadMore';
import Button from '../common/Button';

import Select from "react-select";

 const AssessmentGroups = observer(class AssessmentGroups extends React.Component {
  constructor() {
    super();

    // Bind this to functions
    this.listAssessmentGroups = this.listAssessmentGroups.bind(this);
    this.handleNewAssessmentGroup = this.handleNewAssessmentGroup.bind(this);
    this.listSurveys = this.listSurveys.bind(this);

    this.state = {
      showModal: false,
      studentSurveys: undefined,
      facultySurveys: undefined
    }

    // Default query params
    this.query = {
      limit: 24,
      sortKeys: 'name',
      sortOrder: 'ASC',
      expand: 'branding'
    }
  }

  componentDidMount() {
    this.listAssessmentGroups();
    this.listSurveys();
    this.listBrands();
  }

  listSurveys(){
    const {SurveyStore} = this.props.store;

    SurveyStore.listSurveys();
  }


  listAssessmentGroups() {
    const { AssessmentStore } = this.props.store;

    AssessmentStore.listAssessmentGroups(this.query);
  }

  listBrands() {
    const { AssessmentStore } = this.props.store;

    AssessmentStore.listAssessmentBrands();
  }

  handleNewAssessmentGroup() {
    const { AppStore, AssessmentStore } = this.props.store;

    AssessmentStore.assessmentGroup = null;

    AppStore.drawerContent =
        <EditAssessmentGroup
            {...this.props}
            component="EditAssessmentGroup"
        />;
  }

  setOptions() {
    const { SurveyStore } = this.props.store;

    const options = [{value: undefined, label: 'No change'}, {value: null, label: 'No Survey Selected'}]

    let array = SurveyStore.surveys.map((survey) => {
      const container = {};

      container.value = survey.surveySid;

      container.label = survey.key
          ? survey.name + " (" + survey.key + ")"
          : survey.name;

      return container;
    })

    return [...options, ...array]
  }

  handleInput(surveySid, audience) {
    if(audience === 'student') {
      this.setState({studentSurveys: surveySid})
    } else {
      this.setState({facultySurveys: surveySid})
    }
  }

  handleSubmit(event) {
    const { CourseStore } = this.props.store;
    const { studentSurveys, facultySurveys } = this.state;

    event.preventDefault();
    const callback = this.listAssessmentGroups(this.query);

    CourseStore.setAllSurveys(studentSurveys, facultySurveys, callback)
    this.setState({
      showModal: false,
      studentSurveys: undefined,
      facultySurveys: undefined
    })
    window.location.reload(true);
  }

  render() {
    const { store, translation } = this.props;
    const { AssessmentStore, UserStore } = store;
    const { assessmentGroups, assessmentGroupsNext } = AssessmentStore;
    const { showModal } = this.state;

    return (
        <main
            id="main-content"
            className="page"
            tabIndex="-1"
            aria-labelledby="page-title"
        >
          <Head>
            <title>{translation && translation.heading} | SmarterMeasure</title>
          </Head>

          <Filter
              id="filter"
              filterParams={this.query}
              filterProp="search"
              onClear={this.listAssessmentGroups}
              onFilter={AssessmentStore.listAssessmentGroups}
              placeholder={translation && translation.filter}
              useApi
          />

          <div className="wrap">
            <header className="page__head">
              <h1 id="page-title" className="page__title">
                {translation && translation.heading}
              </h1>
              <div className="btn-group">
                {UserStore.surveysEnabled &&
                <Button
                    className="btn"
                    onClick={() => this.setState({ showModal: true })}
                >
                  {translation.survey_overwrite}
                </Button>
                }
                <Button className="btn" onClick={this.handleNewAssessmentGroup}>
                  {translation && translation.new_group}
                </Button>
              </div>
            </header>

            {assessmentGroups.length > 0 &&
            <ul id="assessment-groups" className="grid grid--wrap" data-columns="2">
              {assessmentGroups.map(
                  (assessmentGroup) =>
                      <li
                          className="grid__item"
                          key={assessmentGroup.assessmentGroupSid}
                      >
                        <AssessmentGroupCard
                            {...this.props}
                            assessmentGroup={assessmentGroup}
                        />
                      </li>
              )}
            </ul>
            }

            {assessmentGroupsNext &&
            <LoadMore
                {...this.props}
                buttonText={translation.load_more}
                loadFunction={(callback) => {
                  AssessmentStore.listAssessmentGroups(deformatQuery(assessmentGroupsNext), true, callback)
                }}
                infinite={true}
            />
            }
            {showModal && (
                <aside className="dialog">
                  <div className="dialog__content">
                    <strong className="dialog__title">
                      {translation.survey_overwrite}
                    </strong>

                    <p>
                      {translation.modal_heading}
                    </p>

                    <form
                        className="form"
                        onSubmit={(e) => this.handleSubmit(e)}
                    >
                      {UserStore.surveysEnabled &&
                      <ul className="form__fields">
                        <li>
                          <label className="form__lbl">
                            {translation.select.student_label}
                            <Select
                              onChange={(e) => this.handleInput(e.value, 'student')}
                              isSearchable
                              options={this.setOptions()}
                              defaultValue={{value: undefined, label: 'No change'}}
                              menuPosition="fixed"
                            />
                          </label>
                        </li>
                        <li>
                          <label className="form__lbl" htmlFor="faculty">
                            {translation.select.faculty_label}
                            <Select
                              onChange={(e) => this.handleInput(e.value, 'faculty')}
                              isSearchable
                              options={this.setOptions()}
                              defaultValue={{value: undefined, label: 'No change'}}
                              menuPosition="fixed"
                            />
                          </label>
                        </li>
                      </ul>
                      }

                      <div className='btn-group'>
                        <Button
                            type="button"
                            className="btn btn--outline reset--z"
                            onClick={()=>this.setState({showModal: false})}
                        >
                          {translation.cancel}
                        </Button>
                        <Button
                            className="btn reset--z"
                            type="submit"
                            disabled={
                              this.state.studentSurveys === undefined && this.state.facultySurveys === undefined
                            }
                        >
                          {translation.save}
                        </Button>
                      </div>
                    </form>
                  </div>
                </aside>
            )}
          </div>
        </main>
    )
  }
})

export default translate('AssessmentGroups')(AssessmentGroups);
