import React from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import Router from './Router';
import { BrowserRouter } from 'react-router-dom';


// Config
import { setToken } from '../config';

// Helpers
import { getData, getParam, getUrlSegment, storeData } from '../helpers';

// Dependencies
import jwt_decode from 'jwt-decode';

// Import common components
import Header from './ui/Header';
import Dialog from './common/Dialog';
import Drawer from './common/Drawer';
import Toast from './common/Toast';



class App extends React.Component {
  constructor(props) {
    super(props);

    // Set the initial state
    this.state = {
      render: false
    };

    // Load translation
    const { AppStore } = props.store;

    if(!AppStore.translation) {
      AppStore.getTranslation();
    }
  }


  componentDidMount() {
    const { skipTtl, store } = this.props;
    const { AppStore, BrandStore, UserStore } = store;
    const { ttlCheckStarted } = UserStore;

    // Set document language
    document.documentElement.lang = 'en';

    // Handle token
    let token = getParam('token', window.location) || getData('token');
    if(token) {
      this.handleToken(token);
    } else {
      this.handleUnauthorized();
    }

    // Start checking token TTL after a minute
    if(!skipTtl && !ttlCheckStarted) {
      UserStore.ttlCheckStarted = true;

      setTimeout(() => {
        UserStore.checkTtl();
      }, 5000);
    }

    // Aids with button focus styles
    document.querySelector('body').addEventListener('click', function(e){
      function unClick(e) {
        e.target.classList.remove('btn--clicked');
        e.target.removeEventListener('blur', this);
      }

      if(e.target.className && typeof e.target.className === 'string') {
        if(
            e.target.className.indexOf('btn') > -1 ||
            e.target.className.indexOf('dropdown__trigger') > -1 ||
            e.target.className.indexOf('select') > -1 ||
            e.target.className.indexOf('switch') > -1 ||
            e.target.className.indexOf('sv_select_wrapper') > -1 ||
            e.target.className.indexOf('sv-boolean') > -1
          ) {
          e.target.classList.add('btn--clicked');
          e.target.addEventListener('blur', () => unClick(e));
        } else if(
          e.target.tagName.toLowerCase() === 'select' &&
          (
            e.target.parentNode.className.indexOf('sv_select_wrapper') > -1 ||
            e.target.parentNode.className.indexOf('select') > -1
          )
        ) {
          e.target.parentNode.classList.add('btn--clicked');

          e.target.parentNode.addEventListener('blur', function(e){
            e.target.parentNode.classList.remove('btn--clicked');
          })
        }
      }
    });

    // Get the user’s time zone
    if(!UserStore.timeZone) {
      UserStore.getUserTimeZone();
    }

    if(AppStore.firstMount) {
      AppStore.firstMount = false;

      const callback = (branding) => {
        const scriptSrc = branding.adobeAnalyticsUrl;
        const placeholder = document.querySelector('#adobe-placeholder');

        // If we don't have a script url, just remove the placeholder
        if (!scriptSrc) {
          placeholder.remove();
          return;
        };

        const adobeScript = document.createElement('script');
        adobeScript.src = scriptSrc;
        adobeScript.async = true;
        adobeScript.onload = () => {
          const recordView = () => {
            // Adobe Analytics
            const dataLayer = {
              page: {
                url: window.location.href
              },
              user: toJS(UserStore.userData)
            };

            // Log attemptSid when learner is taking assessment
            if(UserStore.userData.role?.toLowerCase() === 'learner' && getUrlSegment(1) === 'assessment') {
              dataLayer.attemptSid = getParam('attempt');
            }

            const pageViewEvent = new CustomEvent('page-view', { detail: dataLayer });
            document.dispatchEvent(pageViewEvent);
          }

          if(getUrlSegment(1) === 'assessment') {

            recordView();
          }
        }

        placeholder.replaceWith(adobeScript);
      }

      if (
        window.location.pathname !== '/config-error' &&
        window.location.pathname !== '/sign-in' &&
        !getUrlSegment(1) === 'group'
      ) {
        UserStore.getAccountInstall(window.location.hostname, (installSid) => BrandStore.getBranding(installSid, callback));
      }
    }
  }


  /**
   * handleToken - Stores a token and gets initial data
   * @param {string} token
   */
  handleToken(token) {
    const { UserStore } = this.props.store;

    if(!token) {
      return;
    }

    // Add the token to store
    UserStore.token = token;

    // Add the token to session storage
    storeData('token', token);

    // Add the token to the API header
    setToken(token);

    // Decode the token and store the user data
    let decodedToken = null;

    try {
      decodedToken = jwt_decode(token);
    } catch(error) {
      console.error('Invalid token');
      console.error(error);
    }

    if(!decodedToken || (decodedToken.errorCode === '1110' && token)) {
      // If the token is unauthorized, handle it
      this.handleUnauthorized(token);
    } else {
      // Otherwise, store the decoded token data
      // and get the initial account data
      UserStore.userData = decodedToken;
      UserStore.installSid = UserStore.userData.installSid;
      UserStore.getInstallSettings();

      storeData('userData', JSON.stringify(decodedToken));

      // If the token has custom stylesheet specified, apply it
      if(decodedToken.cssUrl) {
        // TODO: hook up custom stylesheet handling
        console.log('has custom stylesheet');
      }
      if(decodedToken.role){
        UserStore.tokenData = decodedToken;
      }

      if(
        (
          decodedToken.role.toLowerCase() === 'learner' ||
          decodedToken.role.toLowerCase() === 'instructor'
        ) &&
        !UserStore.signOnData
      ) {
        // Get sign on data
        UserStore.getSignOnData(() => {
          this.setState({
            render: true
          });
        });
      } else {
        if(!UserStore.userData.hasOwnProperty('is_user_admin')) {
          UserStore.getUserData(undefined, data => {
            if(data.isSuperAdmin) {
              UserStore.userData.is_super_admin = true;
            }
          });
        }

        this.setState({
          render: true
        });
      }
    }
  }

  /**
   * handleUnauthorized - Redirects unauthorized users
   * @param {string} token
   */
  handleUnauthorized(token) {
    if(
      getUrlSegment(1) !== 'unauthorized' &&
      getUrlSegment(1) !== 'sign-in' &&
      getUrlSegment(1) !== 'group' &&
      getUrlSegment(1) !== 'config-error'
    ) {
      if(token) {
        window.location = `/unauthorized?token=${token}`;
      } else {
        window.location = '/unauthorized';
      }
    }

    this.setState({
      render: true
    });
  }

  render() {
    const { AppStore, UserStore } = this.props.store;
    const { userData } = UserStore;
    const {render} = this.state

    if(render) {
      const attributes = {
        domain: window.location.hostname,
        deploymentSid: userData.deploymentSid,
        installSid: userData.installSid,
        role: userData.role,
        userAgent: navigator.userAgent
      };

      if(userData.courseSid) attributes.courseSid = userData.courseSid;
      if(userData.enrollmentSid) attributes.enrollmentSid = userData.enrollmentSid;
      if(userData.integrationSid) attributes.integrationSid = userData.integrationSid;

      return (
        <BrowserRouter>
          <Header
              {...this.props}
          />
          <div className="viewport">


            <Router {...this.props} />

            {AppStore.dialog  &&
              <Dialog {...this.props} />
            }

            {(AppStore.drawerContent || AppStore.drawerUrl) &&
              <Drawer {...this.props} />
            }

            {AppStore.toast &&
              <Toast
                {...this.props}
                content={AppStore.toast}
                icon={AppStore.toastIcon}
                mod={AppStore.toastMod}
              />
            }
            {AppStore.loading &&
            <aside className="loader">
              <div className="loader__msg">
                <span className="meta">Loading…</span>
              </div>
            </aside>
            }


          </div>
        </BrowserRouter>
      )
    }
    else{
      return null;
    }
  }
}

export default observer(App);
