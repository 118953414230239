/**
 * App router
 */
import React from 'react';
import { Route, Switch } from 'react-router-dom';

// Routes
import { routes } from '../routes';

// Import views

import Home from './views/Home';
import SignIn from './views/SignIn';
import ErrorExpired from "./views/ErrorExpired";
import ErrorConfig from "./views/ErrorConfig";
import ErrorUnauthorized from "./views/ErrorUnauthorized";
import AssessmentGroups from "./views/AssessmentGroups";
import Assessments from "./views/Assessments";
import Surveys from "./views/Surveys";
import Results from "./views/Results";
import Result from "./views/Result";
import {observer} from "mobx-react";
import Assessment from "./views/Assessment";
import Users from "./views/Users";
import User from "./views/User";
import UserAdd from "./views/UserAdd";
import Survey from "./views/Survey";
import LRIReport from "./reports/LRI";

// If you just added a route and it automatically keeps redirecting to /unauthorized
// check the base index.js file for the noTtlUrls var. Adding your route there will
// stop the auto redirect
const Router = observer (class Router extends React.Component {
    render() {
        return (
            <Route
                render={({ location }) => (
                    <Switch location={location}>
                        <Route
                            exact
                            path={routes.index}
                            render={routing => <Home {...routing} {...this.props} />}
                        />
                        <Route
                            path={routes.signIn}
                            render={routing => <SignIn {...routing} {...this.props} />}
                        />
                        <Route
                            path={routes.expired}
                            render={routing => <ErrorExpired {...routing} {...this.props} />}
                        />
                        <Route
                            path={routes.configError}
                            render={routing => <ErrorConfig {...routing} {...this.props} />}
                        />
                        <Route
                            path={routes.unauthorized}
                            render={routing => <ErrorUnauthorized {...routing} {...this.props} />}
                        />
                        <Route
                            path={routes.assessmentGroups}
                            render={routing => <AssessmentGroups {...routing} {...this.props} />}
                        />

                        <Route
                            path={routes.assessments}
                            render={routing => <Assessments {...routing} {...this.props} />}
                        />

                      <Route
                          path={routes.takeAssessment}
                          render={routing => <Assessment {...routing} {...this.props} />}
                      />
                      <Route
                          path={routes.reviewAssessment}
                          render={routing => <Assessment {...routing} {...this.props} />}
                      />

                        <Route
                            path={routes.surveys}
                            render={routing => <Surveys {...routing} {...this.props} />}
                        />
                        <Route
                            path={routes.results}
                            render={routing => <Results {...routing} {...this.props} />}
                        />
                      <Route
                          path={routes.result}
                          render={routing => <Result {...routing} {...this.props} />}
                      />
                      <Route
                          exact
                          path={routes.users}
                          render={routing => <Users {...routing} {...this.props} />}
                      />
                      <Route
                          exact
                          path={routes.userAdd}
                          render={routing => <UserAdd {...routing} {...this.props} />}
                      />
                      <Route
                          path={routes.user}
                          render={routing => <User {...routing} {...this.props} />}
                      />
                      <Route
                          path={routes.survey}
                          render={routing => <Survey {...routing} {...this.props} />}
                      />
                      <Route
                          path={routes.lriReport}
                          render={routing => <LRIReport {...routing} {...this.props} />}
                      />
                    </Switch>
                )}
            />
        );
    }
});

export default Router;
