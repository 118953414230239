/**
 * Renders a bar chart
 */

import React from 'react';
import { arrayChunk } from '../../helpers';

// Icons
import * as icons from '../ui/Icons';
import Button from '../common/Button';

const BarChart = class BarChart extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      selected: null,
      width: 0,
      chunk: 0
    };

    // Create refs
    this.chart = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => {
      this.getDimensions();
    }, 100);

    window.addEventListener('resize', e => {
      this.resizeTimer = setTimeout(() => {
        this.getDimensions();
      }, 100);
    })
  }

  getDimensions() {
    if(this.chart.current) {
      this.setState({
        width: this.chart.current.getBoundingClientRect().width
      });
    }
  }

  fill(value) {
    const { max } = this.props;
    const pct = value / max * 100;

    if(pct > 75) {
      return 'blue';
    } else if(pct > 50) {
      return 'green';
    } else if(pct > 25) {
      return 'yellow';
    }

    return 'red';
  }

  nextPage = data => e => {
    const { chunk } = this.state;

    const idx = chunk > data.length - 1 ?
      data.length - 1
      : chunk + 1

    this.setState({
      chunk: idx
    })
  }

  previousPage = data => e => {
    const { chunk } = this.state;

    const idx = chunk > data.length - 1 ?
      data.length - 2
      : chunk - 1

    this.setState({
      chunk: idx
    })
  }

  render() {
    const {
      data,
      emptyText,
      height = 250,
      keyLabels,
      scoreColors,
      title,
      units,
      pagination
    } = this.props;
    let { levels, max } = this.props;
    const levelArr = [];
    const { chunk } = this.state;

    if(!max) {
      data.forEach(column => {
        if(column.value > max) {
          max = column.value;
        }
      });
    }

    if(levels) {
      const increment = max / levels;

      for(let i = 0; i < levels; i++) {
        if(i !== 0) {
          levelArr.push(Math.floor(increment * i));
        }
      }
    }

    let paginatedData = [];
    let formattedData = [];
    /**
     * Determine if the data is paginated or not. If yes,
     * show the current 'chunk' in the paginated array.
     */
    if (pagination) {
      paginatedData = arrayChunk(data, pagination);
      /**
       * Make sure the index in state is not out of range (happens
       * when props have been updated).
       */
      const idx = chunk > paginatedData.length - 1 ?
        paginatedData.length - 1
        : chunk
      /**
       * Use the first array item as a fallback.
       */
      formattedData = paginatedData[idx] || paginatedData[0];
    } else {
      formattedData = data;
    }

    return (
      <div className="chart">
        <table
          className="barchart"
          style={{ height: height }}
        >
          <caption>{title}</caption>

          <thead>
            {max !== 0 &&
              <tr className="barchart__y-axis">
                <th>0{units || ''}</th>
                {levelArr.map(
                  (level, i) =>
                  <th
                    key={i}
                  >
                    {level}{units || ''}
                  </th>
                )}
                <th>{max}{units || ''}</th>
              </tr>
            }
          </thead>

          <tbody>
            {max !== 0 && formattedData.map(
              (column, i) =>
              <tr
                className="barchart__col"
                key={i}
                style={{ height: height }}
              >
                <th
                  className="barchart__x-lbl"
                  scope="row"
                >
                  {column.label}
                </th>

                {column.value.map(
                  (value, j) =>
                  <td
                    className={`barchart__bar${
                      scoreColors ? ` barchart__bar--${this.fill(value)}` : ''
                    }`}
                    key={j}
                    style={{ height: `${value / max * 100}%` }}
                    title={`${value}${units || ''}`}
                  >
                    <span className="barchart__bar-lbl chart__tooltip">{value}{units || ''}</span>
                  </td>
                )}
              </tr>
            )}
          </tbody>
        </table>

        {keyLabels && keyLabels.length > 0 &&
          <ul className="chart__key chart__key--bar">
            {keyLabels.map(
              (label, i) =>
              <li key={i}>{label}</li>
            )}
          </ul>
        }

        {max === 0 &&
          <span className="chart__no-results">{emptyText}</span>
        }

        {paginatedData && paginatedData.length > 1 &&
          <div className="btn-group btn-group--spaced">
            <Button
              className="btn--outline"
              disabled={this.state.chunk <= 0}
              onClick={this.previousPage(paginatedData)}
            >
              <icons.chevronLeft />
              <span className="meta">Previous results</span>
            </Button>
            <Button
              className="btn--outline"
              disabled={this.state.chunk >= paginatedData.length - 1}
              onClick={this.nextPage(paginatedData)}
            >
              <icons.chevronRight />
              <span className="meta">Next results</span>
            </Button>
          </div>
        }
      </div>
    )
  }
}

export default BarChart;
