import React from 'react';
import { observer } from 'mobx-react';
import BrandStore from '../../stores/BrandStore';

const Button = observer(class Button extends React.Component {
  constructor() {
    super();
  
    this.useBrandStyle = this.useBrandStyle.bind(this)
  }
  // This whole component exists just for this function.
  // This is what allows us to use custom colors pulled from the DB
  useBrandStyle() {
  const { brandColors, useCustomBrand } = BrandStore;
    if (!useCustomBrand) return null;
    
    return {
      "--custom-color": brandColors.primaryButtonColor,
      "--custom-color-alt": brandColors.secondaryButtonColor
    }
  }

  render() {
    const {
      children,
      className,
      id,
      disabled,
      type,
      href,
      tabIndex,
      onClick,
      onMouseDown,
      onKeyDown,
      onFocus,
      refValue,
      value,
      ariaLabel,
      ariaDisabled
    } = this.props;

    return (
      <button
        style={this.useBrandStyle()}
        className={className}
        id={id}
        disabled={disabled}
        type={type}
        href={href}
        tabIndex={tabIndex}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        ref={refValue}
        value={value}
        aria-label={ariaLabel}
        aria-disabled={ariaDisabled}
      >
        {children}
      </button>
    )
  }
});

export default Button;