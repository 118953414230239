import axios from 'axios';

// const { REACT_APP_ENVIRONMENT } = getConfig().publicRuntimeConfig;
// TEMP: Hard code the environment
// const REACT_APP_ENVIRONMENT = 'stage';


export let config = {
  apiUrl: 'https://plus-dev-api.smartermeasure.com/v1',
  apiHealth: 'https://plus-stage-api.smartermeasure.com/',
  spApiUrl: 'https://api-stage.smarterproctoring.com/v1',
  lhApiUrl: 'https://signon-stage.smarterservices.com/v1',
  posthogApiKey: 'phc_suk8Oz36V7K2J9Ln0cXXgF7Cl1edYmjmffAz3Y5SDVc',
  posthogHost: 'https://app.posthog.com',
  adobeScriptSrc: '//assets.adobedtm.com/4ceff8eaacd1/61a75501f0b7/launch-70903f186ff3.min.js',
  compassToolInstalls: ['AI0e45ec9b3d8c47db98313b10590c8bdb'],
  compassToolAdobeScriptSrc: 'https://assets.adobedtm.com/4ceff8eaacd1/48a9f166f540/launch-d13cb643e0bf-development.min.js'
}


// QA
if (process.env.REACT_APP_ENVIRONMENT === 'qa') {
  config.apiUrl = 'https://plus.api.stage.smartermeasure.com/v1';
  config.spApiUrl = 'https://api.smarterproctoring.com/v1';
  config.lhApiUrl = 'https://signon.smarterservices.com/v1';
}
// Production
if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  config.apiUrl = 'https://plus.api.smartermeasure.com/v1';
  config.spApiUrl = 'https://api.smarterproctoring.com/v1';
  config.lhApiUrl = 'https://signon.smarterservices.com/v1';
  config.posthogApiKey = 'phc_AdTlBK0Gsh77tMkF8J0wTBruU6dCz9zeBWjuANMToZW';
  config.compassToolInstalls = [];
  config.compassToolAdobeScriptSrc = 'https://assets.adobedtm.com/4ceff8eaacd1/48a9f166f540/launch-50d3fc748802.min.js';
}

export let api = axios.create({
  baseURL: config.apiUrl
});

export let spApi = axios.create({
  baseURL: config.spApiUrl
});

export let lhApi = axios.create({
  baseURL: config.lhApiUrl
});

export function setToken(token) {
  api = axios.create({
    baseURL: config.apiUrl,
    headers: { token }
  });

  spApi = axios.create({
    baseURL: config.spApiUrl,
    headers: { token }
  });

  lhApi = axios.create({
    baseURL: config.lhApiUrl,
    headers: { token }
  });
}