/**
 * Renders a diff of two assessment version
 */

import React from 'react';
import { observer } from 'mobx-react';

// Translation
import translate from '../translate/Translate';

// Dependencies
import { diff, formatters } from 'jsondiffpatch'

// Icons
import * as icons from '../ui/Icons';

// Components
import Button from '../common/Button';

export const AssessmentDiff = observer(class AssessmentDiff extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      diffMarkup: ''
    };
  }

  componentDidMount() {
    const { assessment, oldVersion } = this.props;
    const diffResult = diff(oldVersion, assessment);

    this.setState({
      diffMarkup: formatters.html.format(diffResult, oldVersion)
    });
  }

  render() {
    const { onClose, title, translation } = this.props;

    return (
      <div className="modal modal--lg">
        <div className="modal__container">
          <article
            className="modal__content"
          >
            <header className="modal__head">
              {title &&
                <h2 className="modal__title">
                  {title}
                </h2>
              }

              <Button
                className="modal__close"
                onClick={onClose}
              >
                <icons.times />
                <span className="meta">{translation.close}</span>
              </Button>
            </header>

            <div dangerouslySetInnerHTML={{ __html: this.state.diffMarkup }} />
          </article>
        </div>
      </div>
    )
  }
})

export default translate('AssessmentDiff')(AssessmentDiff);
