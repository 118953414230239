/**
 * Renders a form to choose an existing assessment or a new one
 */

import React from 'react';
import Select from 'react-select'

// Helpers
import { checkValidity } from '../../helpers';

// Translation
import translate from '../translate/Translate';

// Components
import Button from '../common/Button';


export const EditAssessmentGroupItem = class EditAssessmentGroupItem extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      assessments: [],
      assessmentSid: null,
      maxAttempts: null
    };

    // Bind this to functions
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const { store, translation } = this.props;
    const { AppStore, AssessmentStore } = store;

    AppStore.drawerTitle = translation.heading;

    this.setState({
      assessments: await AssessmentStore.listAssessments()
    });
  }

  handleSubmit() {
    const { assessmentGroupSid, store, translation } = this.props;
    const { assessmentSid, maxAttempts } = this.state;
    const { AppStore, AssessmentStore } = store;

    const payload = {
      assessmentSid
    };

    if(maxAttempts !== null) {
      payload.maxAttempts = maxAttempts;
    } else {
      payload.maxAttempts = 9999;
    }

    const callback = () => {
      AppStore.closeDrawer();
      AppStore.toast = translation.success;
      AssessmentStore.listAssessmentGroups();
    }

    AssessmentStore.createAssessmentGroupItem(assessmentGroupSid, payload, callback);
  }

  render() {
    const { assessments, maxAttempts } = this.state;
    const { store, translation, assessmentGroup } = this.props;
    const { AppStore } = store;

    return (
      <form
        className="form panel panel--md panel--solo"
        noValidate
        onSubmit={(e) => checkValidity(e, this.handleSubmit)}
        onReset={AppStore.closeDrawer}
      >
        <fieldset>
          <ul className="form__fields">
            <li>
              <label
                className="form__lbl"
                htmlFor="group-item-group"
              >
                {translation.group}
              </label>
              <input
                id="group-item-group"
                type="text"
                name="group-name"
                disabled
                aria-disabled="true"
                value={assessmentGroup.name}
              />
            </li>
            <li>
              <label
                className="form__lbl required"
                htmlFor="group-item-assessment"
              >
                {translation.assessment.label}
              </label>

              <Select
                  menuPosition='fixed'
                  onChange={e => this.setState({ assessmentSid: e.value })}
                  required
                  aria-required="true"
                  isSearchable
                  data-errormsg={translation.assessment.error}
                  placeholder={translation.assessment.placeholder}
                  menuPortalTarget={document.getElementById('drawer-content') }
                  options={assessments.map(assessment => {
                    const container = {};
                    container.value = assessment.assessmentSid;
                    container.label = assessment.key ? assessment.name + ' (' + assessment.key + ')' : assessment.name;
                    return container;
                    })}
                />
            </li>


                  <li>
                    <label
                      className="form__lbl"
                      htmlFor="group-item-attempts"
                    >
                      {translation.max_attempts.label}
                    </label>

                    <input
                      type="number"
                      id="group-item-attempts"
                      placeholder={translation.max_attempts.placeholder}
                      onChange={e => {
                        if(e.target.value) {
                          this.setState({ maxAttempts: parseInt(e.target.value, 10) });
                        } else {
                          this.setState({ maxAttempts: null });
                        }
                      }}
                      defaultValue={maxAttempts}
                      min="1"
                      step="1"
                      required={maxAttempts !== null ? true : false}
                      aria-required={maxAttempts !== null ? true : false}
                      data-errormsg={translation.max_attempts.error}
                    />
                  </li>

          </ul>
        </fieldset>

        <div className="panel__action">
          <Button
            className="btn btn--outline"
            type="reset"
          >
            {translation.cancel}
          </Button>

          <Button
            className="btn"
            type="submit"
          >
            {translation.submit}
          </Button>
        </div>
      </form>
    )
  }
}

export default translate('EditAssessmentGroupItem')(EditAssessmentGroupItem);
